import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {LoginService, Principal, StateStorageService, User} from '../../../shared';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {JhiEventManager} from '../../../shared/service/event-manager.service';
import {JhiEventWithContent} from "../../../shared/model/event-with-content.model";
import {RoutingState} from "../../../shared/service/routing-state.service";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzModalRef, NzModalModule, NzModalService} from "ng-zorro-antd/modal";
import {Google2faService} from "../../../shared/service/google2fa.service";
import {HttpClient} from "@angular/common/http";
import {LoginHistoryDTO} from "../../../shared/model/user/login-history.model";
import {LoginHistoryService} from "../../../shared/service/login-history.service";
import {ErrorUtil} from "../../../shared/util/error.util";
import { ExchangeService } from '../../../shared/service/exchange.service';
import { AdminUserService } from '../../../shared/service/admin-user.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
    selector: 'jhi-login',
    templateUrl: './login.component.html',
    styleUrls: [
        'login.scss'
    ]
})
export class JhiLoginComponent implements OnInit {
    validateForm: UntypedFormGroup;
    authenticationError: boolean;
    loggingIn = false;
    passwordVisible = false;
    account = new User();
    captcha: string;
    email: string;
    errorCode: string;
    exchange: number;
    apiKey: string;
    apiSecret: string;
    

    
    @ViewChild('modalContent', { static: false }) modalContent: TemplateRef<any>;
    exchangeAccountList: any;

    loginHistory: LoginHistoryDTO = {ip:'',page:'',userId:0,type:''};
    ipAddress = '';


    constructor(
        private eventManager: JhiEventManager,
        private loginService: LoginService,
        private exchangeService: ExchangeService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private principal: Principal,
        private http:HttpClient,
        private messageService: NzMessageService,
        private routingState: RoutingState,
        private modalService: NzModalService,
        private modalModule: NzModalModule,
        private adminservice: AdminUserService,

        private loginHistoryService: LoginHistoryService,
        protected stateStorageService: StateStorageService,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService
        
    ) {
    }

    ngOnInit() {

        this.validateForm = this.fb.group({
            username: [null, [Validators.required]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            remember: [true]
        });

        this.getIPAddress();
    }


    getIPAddress() {
        this.loginHistoryService.getIPAddress().subscribe((res:any)=>{
            this.stateStorageService.storeCurrentIP(res.IPv4);
        });
    }
        
    
    handleOk(): void {
        
        this.exchangeService.getExchangeAccountVerification(this.exchange, this.account.id, this.apiKey,this.apiSecret).subscribe(result => {


            if(result){
                this.adminservice.updateUserClearedStatus(this.account.id, false)
                .subscribe(() => {
                  this.login();
                  this.redirectToDashboard();
                });
    
            } else {
    
                alert("Invalid account");


            }
           
    });

      }
      

handleCancel(): void {
}


// Abstracting form validation to its own function
validateControls() {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
  }
  
  // Abstracting successful login operations into its own function
  handleLoginSuccess(account) {
    this.eventManager.broadcast(new JhiEventWithContent('authenticationSuccess', 'Sending Authentication Success'));
    this.account = account;
    this.messageService.create('success', `Welcome ${account.login}, you have successfully logged in.`);
  }
  
  // Abstracting error-handling into its own function
  handleLoginError(err) {
    // this.errorCode = err.error.code;
    this.authenticationError = true;
    this.loggingIn = false;
    
    if (err.statusText === 'Unknown Error' || err.error.code === 'DEFAULT_GLOBAL_ERROR') {
      this.messageService.create('error', 'The platform is undergoing maintenance.');
    } else if (err.error.code === 'INVALID_PASSWORD') {
      this.messageService.create('error', 'Invalid credentials. Please try again.');
    }
  }
  
  login() {
    this.validateControls();
  
    if (this.validateForm.valid) {
      this.loggingIn = true;
      this.$localStorage.clear('authenticationToken');
      this.$sessionStorage.clear('authenticationToken');
      this.loginService.login({
        username: this.validateForm.value.username,
        password: this.validateForm.value.password,
        rememberMe: this.validateForm.value.remember
      })
      .then(() => {
        this.authenticationError = false;
        if (['/register', '/finishReset', '/requestReset'].includes(this.router.url) || (/activate/.test(this.router.url))) {
          this.router.navigate(['']);
        }
  
        this.principal.identity(false).then((account) => this.handleLoginSuccess(account));
      })
      .catch((err) => this.handleLoginError(err));
    }
  }
  

        

        async onLoginSuccess() {
            try {
              await this.loginService.login({
                username: this.validateForm.value.username,
                password: this.validateForm.value.password,
                rememberMe: this.validateForm.value.remember
              });
              this.authenticationError = false;
          
              if (this.router.url === '/register' || /activate/.test(this.router.url) ||
                this.router.url === '/finishReset' || this.router.url === '/requestReset') {
                  this.router.navigate(['']);
              }
              
              setTimeout(() => {

              
              this.adminservice.getUserClearedStatus(this.account.id)
                .subscribe(isCleared => {
              
                  if (isCleared) {

                    this.exchangeService.connectedExchangeAccountListName(this.account.id , 'LIVE').subscribe(result => {
                        this.exchangeAccountList = result.map(item => ({ reference: item.reference, id: item.id }));

                });


                    const modal = this.modalService.create({
                      nzTitle: 'Verification',
                      nzContent: this.modalContent,
                      nzClosable: true,
                      nzOnOk: () => this.handleOk(),
                      nzOnCancel: () => this.handleCancel(),
                    });
                  } else if(!this.account.enable2FA){
                    this.redirectToDashboard();
                  }
                });
            },100);

              
            const account = await this.principal.identity(false);
    this.eventManager.broadcast(new JhiEventWithContent('authenticationSuccess', 'Sending Authentication Success'));
    this.account = account;
    if (this.account.enable2FA) {
        const result = await this.show2FAModal();
        if (result && result.valid) {
            this.redirectToDashboard();
        } else {
            this.loggingIn = false;
        }
    } else {
        this.redirectToDashboard();
    }
            } catch (err) {
              this.errorCode = err.error.code;
              this.authenticationError = true;
              this.loggingIn = false;
            }
          }
            

    
            
    

          saveLoginHistory(){
    
    
            this.loginHistory.userId = this.account.id;
            this.loginHistory.ip = this.stateStorageService.getCurrentIP();
            this.loginHistory.page = "login"
            this.loginHistory.type = "login"
    
            this.loginHistoryService.saveLoginHistory(this.loginHistory).subscribe(
                () => {
                },
                (error) => {
                    ErrorUtil.handleError(this.messageService, error);
                }
            );
        }
        async redirectToDashboard() {
            this.saveLoginHistory();
            const redirectUrl = this.routingState.getLastUrl() || '/exchange/connect';
            if(redirectUrl?.includes('payment-checkout')){
                this.router.navigate(['/subscription']);
            }
            else if(redirectUrl?.includes('payment-success') || redirectUrl?.includes('payment-failure')){
                this.router.navigate(['/dashboard']);
            }
            else if(redirectUrl?.includes('strategies-symbol-split')){
                this.router.navigate(['admin/strategies']);
            }
            else{
                this.router.navigate([redirectUrl]);
            }
        }
    

        show2FAModal(): Promise<any> {
            return new Promise((resolve) => {
                const modal = this.modalService.create({
                    nzTitle: "2-Factor Authentication",
                    nzClosable: false,
                    nzContent: ValidateGoogle2FACode,
                    nzComponentParams: {
                        account: this.account
                    }
                });
        
                modal.afterClose.subscribe((result) => {
                    resolve(result);
                });
            });
        }
        
        

    register() {
        this.router.navigate(['/register']);
    }

    requestResetPassword() {
        this.router.navigate(['/reset', 'request']);
    }

    resolve(captchaResponse: string) {
        this.captcha = captchaResponse;
    }
}

@Component({
    template: `
        <div class="text-center">
            <h1>Validate Google authenticator code</h1>
        </div>
        <div class="text-center">
            <p>Enter the Google Authenticator code:</p>
            <nz-input-number [(ngModel)]="pinCode" nzSize="large"></nz-input-number>
        </div>
        <div class="text-center m-t-2" *ngIf="error">
            <p nz-typography nzType="danger">{{error}}</p>
        </div>
    `
})
export class ValidateGoogle2FACode implements OnInit {
    @Input() account: User;
    pinCode: string;
    error: string;

    constructor(
        private modal: NzModalRef,
        private google2faService: Google2faService,
    ) {}

    async ngOnInit() {
        this.modal.updateConfig({
            nzOnOk: () => {
                if (!this.pinCode) {
                    this.error = "Invalid code";
                    return false;
                }

                this.google2faService.validateCode(this.pinCode, this.account.login ?? '').subscribe((result) => {
                    if (result.valid) {
                        this.modal.destroy({ valid: true });
                    } else {
                        this.error = "Invalid code";
                    }
                })

                return false;
            }
        });
    }
}