import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';
import {BehaviorSubject, Observable} from 'rxjs';
import {CoinModel, CurrencyPairCurrentPriceModel, EthaxTokenModel} from "../model/asset/exchange-asset.model";

@Injectable()
export class TickerService {
    private urlResourceUrl = SERVER_API_URL + '/api/tickers';
    private latestTickersSource = new BehaviorSubject<CurrencyPairCurrentPriceModel[]>([]);
    latestTickers$ = this.latestTickersSource.asObservable();
  

    constructor(private http: HttpClient) {}

    getLatestTicker(currencyPair: string, exchangeTopicName: string): Observable<CurrencyPairCurrentPriceModel> {
        return this.http.get<CurrencyPairCurrentPriceModel>(this.urlResourceUrl + "/latest?exchangeTopicName=" + exchangeTopicName + "&currencyPair=" + currencyPair);
    }

    getLatestTickers(exchangeTopicName: string): Observable<CurrencyPairCurrentPriceModel[]> {
        return this.http.get<CurrencyPairCurrentPriceModel[]>(this.urlResourceUrl + "/latest/all?exchangeTopicName=" + exchangeTopicName);
    }

    getTickers24h(exchangeTopicName : string): Observable<CurrencyPairCurrentPriceModel[]> {
        return this.http.get<CurrencyPairCurrentPriceModel[]>(this.urlResourceUrl + "/24h-tickers?exchangeTopicName=" + exchangeTopicName);
    }

    updateLatestTickers(newTickers: CurrencyPairCurrentPriceModel[]) {
        this.latestTickersSource.next(newTickers);
      }
}
