import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RoutingState} from "../../../shared/service/routing-state.service";

@Component({
    selector: 'jhi-social-dispatch',
    template: ''
})
export class SocialDispatchComponent implements OnInit {

    constructor(private router: Router,
                private routingState: RoutingState) {}

    ngOnInit() {
        const redirectUrl = this.routingState.getLastUrl() || '/dashboard';
        this.router.navigate([redirectUrl]);
    }
}
