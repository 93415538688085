import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from '../../../../shared/constants/app.constants';
import {Observable} from 'rxjs';

@Injectable()
export class PasswordResetInitService {

    constructor(private http: HttpClient) {}

    save(mail: string): Observable<any> {
        return this.http.post(SERVER_API_URL + '/api/account/reset_password/init', mail);
    }
}
