import { Component } from "@angular/core";
import { Register } from "./register.service";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { User } from "../../../shared";
import { Router } from "@angular/router";
import { ErrorUtil } from "../../../shared/util/error.util";
import { NzMessageService } from "ng-zorro-antd/message";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { map, tap } from "rxjs/operators";
import { ContactFormModel } from "src/app/shared/model/contact-form.model";

@Component({
  selector: "jhi-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent {
  validateForm: UntypedFormGroup;
  contactForm: UntypedFormGroup;
  isSaving = false;
  isSavingContactForm = false;
  passwordVisible = false;
  captcha: string;
  ipAddress = "";

  constructor(
    private registerService: Register,
    private errorService: NzMessageService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    this.validateForm = this.fb.group({
      fullName: [
        "",
        [
          Validators.minLength(3),
          Validators.required,
          this.noEmojisOrUnwantedCharacters,
        ],
      ],
      email: [
        "",
        [Validators.email, Validators.required, this.anonEmailValidator],
      ],
      password: ["", [Validators.required, Validators.minLength(8)]],
      agreeTerms: [false, Validators.requiredTrue],
    });
    this.contactForm = this.fb.group({
      name: ["", [Validators.required, this.noEmojisOrUnwantedCharacters]],
      surname: ["", [Validators.required, this.noEmojisOrUnwantedCharacters]],
      email: [
        "",
        [Validators.email, Validators.required, this.anonEmailValidator],
      ],
      contactNumber: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
        ],
      ],
    });
  }

  openTermsPDF() {
    window.open("/assets/pdfs/terms.pdf", "_blank");
  }

  register(value: any) {
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }

    if (!this.validateForm.valid) {
      return;
    }

    this.getIPAddress()
      .pipe(
        tap((ipAddress: string) => {
          const registerAccount = new User();
          registerAccount.login = value.email;
          registerAccount.password = value.password;
          registerAccount.fullName = value.fullName;
          registerAccount.currentLanguage =
            window.navigator.language === "ro" ? "ro" : "en";
          registerAccount.registerIpAddress = ipAddress;

          this.registerService.save(registerAccount).subscribe(
            (response) => {
              this.isSaving = false;
              this.router.navigate(["/register-success"], { replaceUrl: true });
            },
            (err) => {
              console.error("API error:", err);
              ErrorUtil.handleError(
                this.errorService,
                err,
                "error",
                this.translateService
              );
            }
          );
        })
      )
      .subscribe();
  }
  submitContact(value: any) {
    for (const key in this.contactForm.controls) {
      this.contactForm.controls[key].markAsDirty();
      this.contactForm.controls[key].updateValueAndValidity();
    }
    if (!this.contactForm.valid) {
      return;
    }
   let contactFormModel: ContactFormModel= {
        name: value.name,
        surname: value.surname,
        email: value.email,
        contactNumber: value.contactNumber,
        message: '',
        source: 'ETHAX_TRADE'
    }
    this.isSavingContactForm = true;
    this.registerService.contactForm(contactFormModel).subscribe(
      (response) => {
        this.isSavingContactForm = false;
        ErrorUtil.handleError(
            this.errorService.success('Form submitted successful!'), ''
          );
          this.router.navigateByUrl('/login');
      },
      (err) => {
        this.isSavingContactForm = false;
        console.error("API error:", err);
        ErrorUtil.handleError(
          this.errorService,
          err,
          "error",
          this.translateService
        );
      }
    );
  }

  getIPAddress() {
    // return this.http.get("https://geolocation-db.com/json/").pipe(
    //   tap((res: any) => {
    //     this.ipAddress = res.IPv4;
    //   }),
    //   map((res: any) => res.IPv4)
    // );
    return this.http.get("https://api.ipify.org?format=json").pipe(
      tap((res: any) => {
        this.ipAddress = res.ip;
      }),
      map((res: any) => res.ip)
    );
  }

  anonEmailValidator = (
    control: UntypedFormControl
  ): { [s: string]: boolean } => {
    const ANON_DOMAINS = [
      "maildrop.cc",
      "mailinator.com",
      "yopmail.com",
      "dispostable.com",
      "sharklasers.com",
      "guerrillamail.info",
      "grr.la",
      "guerrillamail.biz",
      "guerrillamail.com",
      "guerrillamail.de",
      "guerrillamail.net",
      "guerrillamail.org",
      "guerrillamailblock.com",
      "pokemail.net",
      "spam4.me",
      "tempr.email",
      "discard.email",
      "discardmail.de",
      "spambog.com",
      "spambog.de",
      "spambog.ru",
      "0815.ru",
      "knol-power.nl",
      "freundin.ru",
      "smashmail.de",
      "s0ny.net",
      "pecinan.net",
      "budaya-tionghoa.com",
      "lajoska.pe.hu",
      "1mail.x24hr.com",
      "from.onmypc.info",
      "now.mefound.com",
      "mowgli.jungleheart.com",
      "pecinan.org",
      "budayationghoa.com",
      "cr.cloudns.asia",
      "tls.cloudns.asia",
      "msft.cloudns.asia",
      "b.cr.cloudns.asia",
      "ssl.tls.cloudns.asia",
      "sweetxxx.de",
      "dvd.dns-cloud.net",
      "dvd.dnsabr.com",
      "bd.dns-cloud.net",
      "yx.dns-cloud.net",
      "shit.dns-cloud.net",
      "shit.dnsabr.com",
      "eu.dns-cloud.net",
      "eu.dnsabr.com",
      "asia.dnsabr.com",
      "8.dnsabr.com",
      "pw.8.dnsabr.com",
      "mm.8.dnsabr.com",
      "23.8.dnsabr.com",
      "pecinan.com",
      "disposable-email.ml",
      "pw.epac.to",
      "postheo.de",
      "sexy.camdvr.org",
      "disposable.ml",
      "888.dns-cloud.net",
      "adult-work.info",
      "trap-mail.de",
      "gmaile.design",
      "tempes.gq",
      "cpmail.life",
      "tempemail.info",
      "kittenemail.com",
      "99email.xyz",
      "notmyemail.tech",
      "m.cloudns.cl",
      "twitter-sign-in.cf",
      "anonymized.org",
      "you.has.dating",
      "ketoblazepro.com",
      "kost.party",
      "0hio0ak.com",
      "4dentalsolutions.com",
      "t.woeishyang.com",
      "kittenemail.xyz",
      "blackturtle.xyz",
      "geneseeit.com",
      "mailg.ml",
      "media.motornation.buzz",
      "badlion.co.uk",
      "virtual-generations.com",
      "mrdeeps.ml",
      "fouadps.cf",
      "historictheology.com",
      "fshare.ootech.vn",
      "pflege-schoene-haut.de",
      "corona.is.bullsht.dedyn.io",
      "dristypat.com",
      "smack.email",
      "techwizardent.me",
      "mrgamin.ml",
      "mrgamin.gq",
      "mrgamin.cf",
      "tempmail.wizardmail.tech",
      "mail.mrgamin.ml",
      "kaaaxcreators.tk",
      "mail.kaaaxcreators.tk",
      "mail.igosad.me",
      "maa.567map.xyz",
      "32core.live",
      "tokyoto.site",
      "hidemyass.fun",
      "solpatu.space",
      "igosad.tech",
      "mailnesia.com",
      "mailcatch.com",
      "cbrolleru.com",
      "armyspy.com",
      "captainjoso.com",
      "likemaple.com",
      "giftpaper.xyz",
      "motornation.buzz",
      "taokita.org",
      "canixcoffee.com",
    ];
    const email = control.value;
    if (environment.production && email) {
      const domain = email.substr(email.indexOf("@") + 1, email.length);
      const isAnonEmail =
        ANON_DOMAINS.findIndex((v) => v === domain.toLowerCase()) >= 0;
      if (isAnonEmail) {
        return { anon: true };
      }
    }

    return {};
  };

  noEmojisOrUnwantedCharacters(control: UntypedFormControl): {
    [s: string]: boolean;
  } {
    const regex = /^[A-Za-z0-9!@#$%^&*()_+,\-./:;<=>?@\[\]\\\^_`{|}~\s]*$/;
    const isValid = regex.test(control.value);
    if (!isValid) {
      return { unwantedCharacters: true };
    }
    return {};
  }

  resolve(captchaResponse: string) {
    this.captcha = captchaResponse;
  }
}
