import {Injectable, Optional, SkipSelf} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Country} from '../model/country.interface';
import {Language} from '../model/language.interface';

@Injectable()
export class DictionaryService {
    private countryList: Country[];
    private languageList: Language[];

    constructor(@Optional() @SkipSelf() dictionaryService: DictionaryService, private http: HttpClient) {
        if (dictionaryService) {
            throw new Error('Dictionary service can be instantiated only once!');
        }
    }

    public init() {
        this.loadCountries();
        this.loadLanguages();
    }

    public countryCode(countryName: string) {
        if (!countryName) {
            throw new Error('Can\'t determine code of a null country');
        }
        const candidates = this.countryList.filter((c) => c.name.toLowerCase() === countryName.toLowerCase());

        return (candidates && candidates.length > 0) ? candidates[0].code.toLowerCase() : null;
    }

    public countryName(countryCode: string) {
        if (!countryCode) {
            throw new Error('Can\'t determine code of a null country');
        }
        const candidates = this.countryList.filter((c) => c.code.toLowerCase() === countryCode.toLowerCase());

        return (candidates && candidates.length > 0) ? candidates[0].name.toLowerCase() : null;
    }

    public countries() {
        return this.countryList;
    }

    public languages() {
        return this.languageList;
    }

    private loadCountries() {
        this.http.get('./assets/json/countries.json').subscribe((data: Country[]) => {
            this.countryList = data;
        });
    }

    private loadLanguages() {
        this.http.get('./assets/json/languages.json').subscribe((data: Language[]) => {
            this.languageList = data;
        });
    }
}
