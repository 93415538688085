import { Routes } from '@angular/router';

import {
    activateRoute,
    passwordResetFinishRoute,
    passwordResetInitRoute,
    registerRoute,
    socialRegisterRoute,
    socialAuthRoute,
    registerSuccessRoute,
    passwordResetInitSuccessRoute,
    passwordResetFinishSuccessRoute,
    socialDispatchRoute
} from './index';
import {loginRoutes} from './login/login.route';
import {PublicLayoutComponent} from '../components/layout/public-layout.component';
import {logoutRoutes} from './logout/logout.route';

const PUBLIC_ROUTES = [
    activateRoute,
    passwordResetFinishRoute,
    passwordResetFinishSuccessRoute,
    passwordResetInitRoute,
    passwordResetInitSuccessRoute,
    registerRoute,
    registerSuccessRoute,
    socialRegisterRoute,
    ...loginRoutes,
    ...logoutRoutes
];

export const accountState: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
    },
    {
        path: '',
        component: PublicLayoutComponent,
        children: PUBLIC_ROUTES
    },
    socialAuthRoute,
    socialDispatchRoute
];
