import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {Indicators, Strategy, StrategyModel} from "../model/strategy/strategy.model";
import {ExchangeAccountModelDTO} from "../model/exchange/exchange.model";
import {
    CNNFearAndGreed,
    CryptoRadars,
    FNGAlternative,
    FNGCnn,
    RedditSentiments
} from "../model/sentiment/sentiment.model";

@Injectable()
export class SentimentService {
    private sentimentResourceUrl = SERVER_API_URL + '/api/sentiment';

    constructor(private http: HttpClient) {}

    getRedditModel(limit: number): Observable<RedditSentiments> {
        return this.http.get<RedditSentiments>(this.sentimentResourceUrl + "/reddit?limit=" + limit);
    }

    getCryptoRadarModel(): Observable<CryptoRadars> {
        return this.http.get<CryptoRadars>(this.sentimentResourceUrl + "/cryptoradar");
    }

    getFNGAlternativeModel(): Observable<FNGAlternative> {
        return this.http.get<FNGAlternative>(this.sentimentResourceUrl + "/alternative");
    }

    getFNGCNNModel(): Observable<FNGCnn> {
        return this.http.get<FNGCnn>(this.sentimentResourceUrl + "/cnn");
    }

}
