import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import * as _ from 'lodash';
import {Principal} from './principal.service';

@Injectable()
export class UserRouteAccessService implements CanActivate {

  constructor(private router: Router,
              private principal: Principal) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {

    const authorities = route.data['authorities'];
    if (!authorities || authorities.length === 0) {
      return true;
    }

    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Promise<boolean> {
    const principal = this.principal;
    return Promise.resolve(principal.identity().then((account) => {

      if (account && principal.hasAnyAuthorityDirect(authorities) && this.allowedForLdapUsers(account, url)) {
        return true;
      }

      this.router.navigate(['accessdenied']).then(() => {
        // only show the login dialog, if the user hasn't logged in yet
        if (!account) {
          this.router.navigate(['/login'], {replaceUrl: true});
        }
      });

      return false;
    }));
  }

  allowedForLdapUsers(account: any, url: string) {
      if (!account.ldapUser) {
        return true;
      }

      const RESTRICTED_FOR_LDAP_USERS = ['/user/billing', '/user/account', '/user/support', '/user/addpayment', '/infra/vm/new',
                                         '/infra/lbr/new', '/infra/db/new', '/infra/vm/snapshots', '/infra/vm/backups',
                                         '/infra/vm/new', '/dashboard/create-service', '/dashboard/template', '/exchange'];
      return _.filter(RESTRICTED_FOR_LDAP_USERS, route => url.indexOf(route) >= 0).length === 0;
  }
}
