<div class="wrapper text-center">

    <ul>
        <li><a></a></li>
        <li><a>Help</a></li>
        <li><a>Careers</a></li>
        <li><a>Terms</a></li>
        <li><a>Privacy</a></li>
    </ul>
    <a href="" class="footerLogo">
        <svg width="84" height="28" viewBox="0 0 84 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="28" height="28" fill="#F95C3A"/>
            <path d="M60.6666 0H84L74.6666 28H51.3333L60.6666 0Z" fill="#7737FE"/>
            <path d="M56 14L84 -1.57345e-06L84 28L56 14Z" fill="#FFC24A"/>
            <circle cx="42" cy="14" r="14" fill="#298AFC"/>
            <path d="M17.7333 9.33325L36.4 27.9999H17.7333V9.33325Z" fill="#6AEF9E"/>
        </svg>
    </a>
</div>

