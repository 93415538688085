import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {User} from '../model/user/user.model';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';

@Injectable()
export class AdminUserService {
    private resourceUrl = SERVER_API_URL + '/api/admin';

    constructor(private http: HttpClient) {}

    getAllUsers(): Observable<User[]> {
        return this.http.get<User[]>(this.resourceUrl + '/users');
    }


    updateUser(user: User){
        return this.http.put(this.resourceUrl + '/user/update', user);
    }

    lockUnlockUser(user: User){
        return this.http.put(this.resourceUrl + '/user/lock-unlock', user);
    }

    clearUserSecurity(user: User){
        return this.http.put(this.resourceUrl + '/user/clear-security-settings', user);
    }

    getUserClearedStatus(id: number): Observable<User> {
        return this.http.get<User>(this.resourceUrl + '/user/' + id);
    }

    updateUserClearedStatus(id: number, cleared: boolean) {
        return this.http.put(this.resourceUrl + '/user/statuschange/' + id, cleared);
    }
    

    deleteAccount(id: number): Observable<any> {
        return this.http.delete(this.resourceUrl + '/deluser/' + id);
    }

}
