import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {User} from '../../index';
import {SERVER_API_URL} from '../../constants/app.constants';
import {Observable} from 'rxjs';

@Injectable()
export class AccountService {
  private resourceUrl = SERVER_API_URL + '/api/account';

  constructor(private http: HttpClient) {
  }

  get(): Observable<HttpResponse<User>> {
    return this.http.get<User>(this.resourceUrl, {observe: 'response'});
  }

  logout(): Observable<HttpResponse<User>> {
      return this.http.get<User>(SERVER_API_URL + '/api/logout', {observe: 'response'});
  }

  deleteAccount(user: User): Observable<any> {
      return this.http.post<any>(this.resourceUrl + '/delete', user, {observe: 'response'});
  }
}
