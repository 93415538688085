import { Route } from '@angular/router';

import { UserRouteAccessService } from '../../../../shared';
import { PasswordResetInitComponent } from './password-reset-init.component';
import {PasswordResetInitSuccessComponent} from './password-reset-init-success.component';

export const passwordResetInitRoute: Route = {
    path: 'reset/request',
    component: PasswordResetInitComponent,
    data: {
        authorities: [],
        pageTitle: 'global.menu.account.password'
    },
    canActivate: [UserRouteAccessService]
};

export const passwordResetInitSuccessRoute: Route = {
    path: 'reset/request-success',
    component: PasswordResetInitSuccessComponent,
    data: {
        authorities: [],
        pageTitle: 'global.menu.account.password'
    },
    canActivate: [UserRouteAccessService]
};
