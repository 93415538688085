import {Injectable, Optional, SkipSelf} from '@angular/core';
import {SERVER_API_URL} from '../constants/app.constants';
import * as Stomp from 'webstomp-client';
import {Subscription} from 'rxjs';
import {AuthServerProvider} from './auth/auth-jwt.service';
import * as SockJS from 'sockjs-client';
import * as _ from 'lodash';
import {WebsocketClient} from '../util/websocket-client';
import {JhiEventManager} from "./event-manager.service";

@Injectable()
export class WebsocketService {
    public static TOPIC_NOTIFICATIONS = '/notifications';
    public static TOPIC_TICKERS = '/tickers';

    private stompClient;
    private retrySubscription: Subscription;
    private clients: Map<string, Promise<WebsocketClient>>;
    private websocketClients: Map<string, WebsocketClient>;
    private connected: boolean;
    private connectionPromise: Promise<boolean>;

    constructor(@Optional() @SkipSelf() websocketService: WebsocketService,
                private authServerProvider: AuthServerProvider,
                public eventManager: JhiEventManager) {
        if (websocketService) {
            throw new Error('Websocket service can be instantiated only once!');
        }
        this.clients = new Map();
        this.websocketClients = new Map();
    }

    private connect(): Promise<boolean> {
        if (!this.connectionPromise) {
            this.connectionPromise = new Promise((resolve, reject) => {
                if (this.connected) {
                    resolve(this.connected);
                }
                let url = SERVER_API_URL + '/websocket/tracker';
                const authToken = this.authServerProvider.getToken();

                if (authToken) {
                    url += '?access_token=' + authToken;
                }

                const socket = new SockJS(url);
                this.stompClient = Stomp.over(socket, { heartbeat: false, debug: false });
                const successCallback = _.partial(this.connectionSuccess.bind(this), resolve);
                this.stompClient.connect({},
                    () => {
                        successCallback();
                        resolve(true);
                    },
                    () => {
                        this.reconnect(url, successCallback)
                    }
                );

                const self = this;
                this.stompClient.ws.onclose = function(e) {
                    self.eventManager.broadcast('ws.connection.lost');
                    self.connected = false;
                    self.reconnect(url, successCallback);
                };
            });
        }
        return this.connectionPromise;
    }

    private connectionSuccess(resolve) {
        this.eventManager.broadcast('ws.connection.gained');
        this.connected = true;
    }

    public listen(id: string, topic: string): Promise<WebsocketClient> {
        let _promise;
        if (this.clients.has(id)) {
            _promise = this.clients.get(id);
        } else {
            _promise = new Promise((resolve, reject) => {
                this.connect().then((result) => {
                    const client = new WebsocketClient();
                    client.topic = topic;
                    client.subscription = this.stompClient.subscribe('/user/topic' + topic, (data) => {
                        client.clientObserver.next(JSON.parse(data.body));
                    });
                    this.websocketClients.set(id, client);
                    resolve(client);
                });
            });
            this.clients.set(id, _promise);
        }

        return _promise;
    }

    public stopListening(id: string) {
        if (this.clients.has(id)) {
            this.clients.get(id).then((client) => client.unsubscribe());
            this.clients.delete(id);
        }
    }

    public destroy() {
        this.disconnect();
    }

    private disconnect() {
        if (this.stompClient) {
            if (this.stompClient.connected) {
                this.stompClient.disconnect();
            }
            this.stompClient = null;
        }
        if (this.retrySubscription) {
            this.retrySubscription.unsubscribe();
            this.retrySubscription = null;
        }
        this.connectionPromise = null;
        this.connected = false;
    }

    private reconnect(url, successCallback) {
        this.connectionPromise = null;
        this.connected = false;
        let reconInv = setInterval(() => {
            const socket = new SockJS(url);
            this.stompClient = Stomp.over(socket, { heartbeat: false, debug: false });
            this.stompClient.connect({}, (frame) => {
                clearInterval(reconInv);
                this.connected = true;
                // reconnect clients
                this.websocketClients.forEach(((client, key) => {
                    client.subscription.unsubscribe();
                    client.subscription = this.stompClient.subscribe('/user/topic' + client.topic, (data) => {
                        client.clientObserver.next(JSON.parse(data.body));
                    });
                }));
                successCallback();
            }, () => {
                if (this.connected) {
                    this.reconnect(url, successCallback);
                }
            });
        }, 1000);
    }
}
