import {Component} from '@angular/core';

import {PasswordResetInitService} from './password-reset-init.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ErrorUtil} from '../../../../shared/util/error.util';
import {Router} from '@angular/router';
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
    selector: 'jhi-password-reset-init',
    templateUrl: './password-reset-init.component.html',
    styleUrls: [ 'password-reset-init.component.scss']
})
export class PasswordResetInitComponent {
    validateForm: UntypedFormGroup;
    captcha: string;

    constructor(
        private passwordResetInitService: PasswordResetInitService,
        private errorService: NzMessageService,
        private router: Router,
        private fb: UntypedFormBuilder,
    ) {
        this.validateForm = this.fb.group({
            email: ['', [Validators.email, Validators.required]],
        });
    }

    resetPassword(value: any) {
        for (const key in this.validateForm.controls) {
            this.validateForm.controls[key].markAsDirty();
            this.validateForm.controls[key].updateValueAndValidity();
        }

        if (!this.validateForm.valid) {
            return;
        }
        this.passwordResetInitService.save(value.email).subscribe(() => {
            this.router.navigate(['/reset/request-success']);
        }, (response) => {
            ErrorUtil.handleError(this.errorService, response)
        });
    }

    resolve(captchaResponse: string) {
        this.captcha = captchaResponse;
    }
}
