import {LogoutComponent} from './logout.component';
import {Routes} from '@angular/router';

export const logoutRoutes: Routes = [
    {
        path: 'logout',
        component: LogoutComponent,
        data: {
            authorities: [],
        }
    }
];
