import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../constants/app.constants';
import { Indicators, NewStrategyModel, StrategyModel } from "../model/strategy/strategy.model";

@Injectable()
export class StrategyService {
    private strategyResourceUrl = SERVER_API_URL + '/api/strategies';
    private indicatorResourceUrl = SERVER_API_URL + '/api/indicators';

    constructor(private http: HttpClient) { }


    getAllIndicators(): Observable<Indicators[]> {
        return this.http.get<Indicators[]>(this.indicatorResourceUrl);
    }

    getAllStrategy(page, size, planName?,strategyName?, exchangeId?, quoteAmount?): Observable<any> {
        const params = new HttpParams()
            .set('page', page.toString())
            .set('size', size.toString())
            .set('sort', 'id,desc')
            .set('planName', planName ?? '')
            .set('strategyName', strategyName ?? '')
            .set('exchangeId', exchangeId ?? '')
            .set('quoteAmount', quoteAmount ?? '');
    
        return this.http.get<any>(this.strategyResourceUrl, { params });
    }
    
    
    
    
    

    createStrategy(strategy: NewStrategyModel): Observable<any> {
        return this.http.post<NewStrategyModel>(this.strategyResourceUrl + '/add', strategy);
    }

    editStrategy(strategy: StrategyModel, id: any): Observable<any> {
        return this.http.put<StrategyModel>(this.strategyResourceUrl + '/' + id, strategy);
    }

    deleteStrategy(id: number): Observable<any> {
        return this.http.delete(this.strategyResourceUrl + '/' + id);
    }

    findStrategy(id: number): Observable<StrategyModel> {
        return this.http.get<StrategyModel>(this.strategyResourceUrl + '/' + id);
    }

    getAllPeriods(): Observable<any[]> {
        return this.http.get<any[]>(this.strategyResourceUrl + '/periods');
    }

    getAllDurations(): Observable<any[]> {
        return this.http.get<any[]>(this.strategyResourceUrl + '/timeframe');
    }

}
