import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient, HttpContext} from '@angular/common/http';
import {SERVER_API_URL} from "../constants/app.constants";
import {Observable, Subject} from 'rxjs';
import {NGX_LOADING_BAR_IGNORED} from "@ngx-loading-bar/http-client";

@Injectable()
export class CurrencyService implements OnDestroy {
    private exchangeResourceUrl = SERVER_API_URL + '/api/currency';
    private stopPolling = new Subject();

    constructor(private http: HttpClient) {}

    getCurrencyList(): Observable<string[]> {
        return this.http.get<string[]>(this.exchangeResourceUrl + "/list");
    }

    getPrices(coins: string[]): Observable<any> {
        const param = coins.join(",");
        return this.http.get<any>(this.exchangeResourceUrl + "/prices?coins="+param, {
            context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
        });
    }

    ngOnDestroy() {
        this.stopPolling.next();
    }
}
