import {Injectable} from '@angular/core';
import {HttpClient, HttpContext} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {
    ExchangeModel,
    ExchangeAccountModel,
    ExchangeAccountModelDTO,
    WalletHistoryDto, AccountStatisticsDto, ExchangeCheckStatusModel
} from '../model/exchange/exchange.model';
import {CurrencyPairCurrentPriceModel, TickersModel} from "../model/asset/exchange-asset.model";
import {NGX_LOADING_BAR_IGNORED} from "@ngx-loading-bar/http-client";

@Injectable()
export class ExchangeService {
    private exchangeResourceUrl = SERVER_API_URL + '/api/exchanges';

    private exchangeAccountResourceUrl = SERVER_API_URL + '/api/exchange-account/';

    constructor(private http: HttpClient) {}

    getExchangeList(type: string): Observable<ExchangeModel[]> {
        return this.http.get<ExchangeModel[]>(this.exchangeResourceUrl + '/' + type);
    }

    getAllExchangeList(): Observable<ExchangeModel[]> {
        return this.http.get<ExchangeModel[]>(this.exchangeResourceUrl);
    }

    getExchange(id: number): Observable<ExchangeAccountModel> {
        return this.http.get<ExchangeAccountModel>(this.exchangeAccountResourceUrl + id);
    }

    getExchangeAccount(id: number): Observable<ExchangeAccountModel> {
        return this.http.get<ExchangeAccountModel>(this.exchangeAccountResourceUrl +  id);
    }

    getExchangeStatus(): Observable<ExchangeCheckStatusModel> {
        return this.http.get<ExchangeCheckStatusModel>(`${SERVER_API_URL}/api/board/exchange/register/check`, {
            context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
        });
    }
    connectedExchangeAccountList(type: string): Observable<ExchangeAccountModel[]> {
        return this.http.get<ExchangeAccountModel[]>(this.exchangeAccountResourceUrl + type + '/list', {
            context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
        });
    }

    listAccounts(params: any): Observable<any[]> {
        return this.http.get<any[]>(this.exchangeAccountResourceUrl+'accounts',{ params: params });
    }

    connectedExchangeAccountListName(id: number, type: string): Observable<ExchangeAccountModel[]> {
        return this.http.get<ExchangeAccountModel[]>(this.exchangeAccountResourceUrl + id + '/' + type + '/listname', {
            context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),
        });
    }

    getExchangeAccountVerification(idaccount: number, iduser: number, userapikey: string, usersecretkey: string): Observable<Boolean> {
        return this.http.get<Boolean>(this.exchangeAccountResourceUrl + 'verification' + '/' +  idaccount + '/' + iduser + '/' + userapikey + '/' + usersecretkey);
    }

    addExchangeAccount(exchangeAccount: ExchangeAccountModelDTO): Observable<any> {
        return this.http.post<ExchangeAccountModelDTO>(this.exchangeAccountResourceUrl + 'add', exchangeAccount);
    }

    connectExchangeAccount(exchangeAccountId: number): Observable<any> {
        return this.http.put(this.exchangeAccountResourceUrl + exchangeAccountId + "/connect", null);
    }

    deleteExchangeAccount(exchangeAccountId: number): Observable<any> {
        return this.http.delete(this.exchangeAccountResourceUrl + exchangeAccountId);
    }

    getCurrencyCurrentPrice(): Observable<TickersModel>{
        return this.http.get<TickersModel>(this.exchangeAccountResourceUrl  + "kafka-listener/get-current-price");
    }

    getExchangeAccountDailyHistory(accountId: number, days: number): Observable<WalletHistoryDto[]>{
        return this.http.get<WalletHistoryDto[]>(this.exchangeAccountResourceUrl  + accountId + "/daily-wallet-history?days=" + days);
    }

    getAccountStatistics(accountId: number) : Observable<AccountStatisticsDto> {
        return this.http.get<any>(this.exchangeAccountResourceUrl + accountId + "/account-statistics");
    }


    // getPrice24HoursAgo(accountId: number) {
    //     let now = new Date();
    //     let 24HoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));
    //     let formattedDate = 24HoursAgo.toISOString();
    //     return this.http.get(${this.exchangeAccountResourceUrl}${accountId}/price-24-hours-ago?date=${formattedDate}).pipe(
    //     map(response => {
    //     return response.data.price
    //     })
    //     );
    //     }


}
