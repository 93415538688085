import {Routes} from '@angular/router';

import {UserRouteAccessService} from '../../../shared/index';
import {JhiLoginComponent} from './login.component';

export const loginRoutes: Routes = [
    {
        path: 'login',
        component: JhiLoginComponent,
        data: {
            authorities: [],
            pageTitle: 'login.title'
        },
        canActivate: [UserRouteAccessService]
    },
    {
        path: 'login/:ref',
        component: JhiLoginComponent,
        data: {
            authorities: [],
            pageTitle: 'login.title'
        },
        canActivate: [UserRouteAccessService]
    }
];
