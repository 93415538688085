import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {LoginService} from '../../../shared';
import {Principal, StateStorageService} from '../../../shared';
import {CookieService} from 'ng2-cookies';
import {RoutingState} from "../../../shared/service/routing-state.service";

@Component({
    selector: 'jhi-auth',
    template: ''
})
export class SocialAuthComponent implements OnInit {

    constructor(
        private loginService: LoginService,
        private cookieService: CookieService,
        private router: Router,
        private principal: Principal,
        private stateStorageService: StateStorageService,
        private routingState: RoutingState) {}

    ngOnInit() {
        const token = this.cookieService.get('social-authentication');
        if (token && token.length) {
            this.loginService.loginWithToken(token, false).then(() => {
                    this.cookieService.delete('social-authentication');
                    this.principal.identity(true).then((account) => {
                        this.stateStorageService.storeCurrentAccount(account);
                    });

                    const redirectUrl = this.routingState.getLastUrl() || '/dashboard';
                    this.router.navigate([redirectUrl]);
                 }, () => {
                    this.router.navigate(['social-register'], {queryParams: {'success': 'false'}});
            });
        } else {
            this.router.navigate(['/login']);
        }
    }
}
