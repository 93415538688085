import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {Indicators, Strategy, StrategyModel} from "../model/strategy/strategy.model";
import {ExchangeAccountModelDTO} from "../model/exchange/exchange.model";
import {
    StrategyExecution,
    StrategyExecutionInstance,
    StrategyExecutionModel
} from "../model/strategy-execution/strategy-execution.model";
import {PredefineStrategyModel} from "../model/strategy/predefined-strategy.model";

@Injectable()
export class PredefinedStrategyService {
    private strategyExecutionResourceUrl = SERVER_API_URL + '/api/predefine-strategies';

    constructor(private http: HttpClient) {}

    getAllStrategyExecution(id: any): Observable<StrategyExecution[]> {
        return this.http.get<StrategyExecution[]>(this.strategyExecutionResourceUrl + "/account/" + id);
    }

    createPredefineStrategy(predefineStrategyModel: PredefineStrategyModel, accountId: number): Observable<any> {
        return this.http.post<StrategyExecutionModel>(this.strategyExecutionResourceUrl + "/add/" + accountId,  predefineStrategyModel);
    }

    runPredefineStrategyExecution(predefineStrategyModel: PredefineStrategyModel, accountId: number, strategyId: number): Observable<any> {
        return this.http.post<StrategyExecutionModel>(this.strategyExecutionResourceUrl + "/" + accountId + '/run/' + strategyId,  predefineStrategyModel);
    }
}
