import {TranslateService} from "@ngx-translate/core";

export class ErrorUtil {

    static handleError(errorService, err, level?, translationService?) {
        const error = err.error;
        if (error && error.code) {
            ErrorUtil.translated(error.deepMessage ? error.deepMessage :
                                    (this.translatableCode(error.code) ? error.code : error.message), translationService)
                .then((result) => errorService.error(result, {nzDuration: 5000}));
        } else if (error) {
            if (level === 'info') {
                ErrorUtil.translated(error.message, translationService)
                    .then((result) => errorService.info(result, {nzDuration: 5000}));
            } else if (level === 'warn') {
                ErrorUtil.translated(error.message, translationService)
                    .then((result) => errorService.warning(result, {nzDuration: 5000}));
            } else {
                ErrorUtil.translated(error.message, translationService)
                    .then((result) => errorService.error(result, {nzDuration: 5000}));
            }
        } else {
            console.log(err);
        }
    }

    static async translated(message: string, translateService: TranslateService) {
        if (!translateService ||
                (!(message in ErrorUtil.MESSAGE_MAP) && ErrorUtil.TRANSLATABLE_CODES.indexOf(message) < 0)) {
            return message;
        }
        return await translateService.get(ErrorUtil.mapMessage(message)).toPromise();
    }

    static mapMessage(message: string) {
        return (message in ErrorUtil.MESSAGE_MAP) ?
                    ErrorUtil.MESSAGE_MAP[message] : message;
    }

    static translatableCode(code: string) {
        if (!code) {
            return false;
        }
        return ErrorUtil.TRANSLATABLE_CODES.indexOf(code) >= 0;
    }

    static TRANSLATABLE_CODES = ['EMAIL_REGISTERED', 'PAYMENT_INFO_INVALID', 'CARD_EXPIRED', 'CANNOT_GET_BALANCE', 'ZONE_CONFLICT', 'UNEXPECTED'];

    static MESSAGE_MAP = {
        // From Hetzner
        "invalid input in field 'size'": "volume-size-error",
        "name is already used": "unique-name-error",
        "name already used": "unique-name-error",
        "public interface can only be disabled if the load balancer is in a network": "lbr-dis-error",
        "cannot perform operation because server is locked": "server-locked-error",
        "server name is already used": "used-name-error",
        "volume name is already used": "used-name-error",
        "load balancer is not attached to a network": "not-attached-lbr",
        "server is not attached to the load balancer's network": "server-not-attached",
        "a service for the given source port is already defined": "source-port-defined",
        "invalid input in field 'http.certificates'": "invalid-certs",
        "port 80 is busy, redirect_http is enabled on another service": "port-busy"
    }
}
