import {Component, OnInit} from "@angular/core";
import {ExchangeAssetModel} from "../model/asset/exchange-asset.model";
import {ExchangeAccountModel, WalletModel} from "../model/exchange/exchange.model";
import {StateStorageService} from "../service/auth/state-storage.service";
import {ErrorUtil} from "../util/error.util";
import {NzMessageService} from "ng-zorro-antd/message";
import {ExchangeService} from "../service/exchange.service";
import {Principal} from "../service/auth/principal.service";
import {User} from "../model/user/user.model";
import {CurrencyService} from "../service/currency.service";
import {TickerService} from "../service/ticker.service";
import {CurrencyPair} from "../util/currency.util";

@Component({template: ''})
export abstract class AssetAwareComponent implements OnInit {
    enablePaperTrade: boolean;
    account: User = this.stateStorageService.getCurrentAccount();

    constructor(
        protected messageService: NzMessageService,
        protected exchangeService: ExchangeService,
        protected stateStorageService: StateStorageService,
        protected principal: Principal,
        protected currencyService: CurrencyService,
        protected tickerService: TickerService
    ) {
        this.enablePaperTrade = this.stateStorageService.getCurrentAccount().enablePaperTrading;
    }

    async ngOnInit() {
        this.account = await this.principal.identity(true);
    }

    protected async fetchExternalBtcEthEthaxPrices() {
        const prices = await this.currencyService.getPrices(["BTC", "ETH", "ETHAX"]).toPromise();
        return {
            btc_usd: prices['BTC'],
            eth_usd: prices['ETH'],
            ethax_usd: prices['ETHAX'],
        }
    }

    protected getAssetBalance(exchangeAccount: ExchangeAccountModel): Map<string, number> {
        const result = new Map<string, number>();
        let balances = new Map(Object.entries(exchangeAccount.tradingWallet.balances));
        balances.forEach((value: WalletModel, key: string) => {
            result.set(key, value.total);
        });
        return result;
    }

    protected getAllAssetBalance(exchangeAccounts: ExchangeAccountModel[]): Map<string, number> {
        const result = new Map<string, number>();
        exchangeAccounts.forEach(account => {
            this.getAssetBalance(account).forEach((balance, asset) => {
                if (result.has(asset)) {
                    result.set(asset, result.get(asset) + balance);
                } else {
                    result.set(asset, balance);
                }
            });
        });
        return result;
    }

    protected async getAssetBalanceWithUsdPrices(exchangeAccounts: ExchangeAccountModel[]): Promise<ExchangeAssetModel[]> {
        const result = new Map<string, ExchangeAssetModel>();
    
        for (let account of exchangeAccounts) {
            const balances = this.getAssetBalance(account);
            const tickers = await this.tickerService.getLatestTickers(account.exchange.exchangeName).toPromise();
            const counterCoins = ["USD", "BTC", "ETH", "DAI", "USDT", "BUSD", "USDC", "USDP", "TUSD", "USDD"];
            const stableCoins = ["DAI", "USDT", "BUSD", "USDC", "USDP", "TUSD", "USDD"];
            const externalPrices = await this.currencyService.getPrices(counterCoins).toPromise();
            balances.forEach((balance, asset) => {
                // If the asset is a stable coin, set its price to 1
                if (stableCoins.includes(asset)) {
                    result.set(asset, new ExchangeAssetModel(
                        asset,
                        0,
                        0,
                        1,
                        balance,
                        balance
                    ));
                } else {
                    let foundTickers = tickers.filter(t => t.instrument.startsWith(`${asset}/`));
                    if (!foundTickers.length) {
                        return;
                    }
                    let foundTicker = foundTickers.find(t => counterCoins.indexOf(new CurrencyPair(t.instrument).market()) >= 0);
                    if (foundTicker) {
                        const counter = new CurrencyPair(foundTicker.instrument).market();
                        const exteralPrice = externalPrices[counter];
                        if (result.has(asset)) {
                            result.get(asset).amount += balance;
                        } else {
                            result.set(asset, new ExchangeAssetModel(
                                asset,
                                0,
                                0,
                                foundTicker.last * exteralPrice,
                                balance,
                                foundTicker.last * balance * exteralPrice
                            ));
                        }
                    } else {
                        // the pair is not available as a ticker, ignore it
                        return;
                    }
                }
            });
        }
    
        return Array.from(result.values());
    }

    protected async getConnectedExchangeAccounts(): Promise<ExchangeAccountModel[]> {
        return this.exchangeService.connectedExchangeAccountList(this.enablePaperTrade ? 'PAPER_TRADE' : 'LIVE').toPromise();
    }
}