import {Injectable} from '@angular/core';
import {GOOGLE, SERVER_API_URL} from '../../constants/app.constants';

@Injectable()
export class SocialService {
    constructor() {}

    getProviderSetting(provider) {
        switch (provider) {
            case GOOGLE: return 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email';
            default: return 'Provider setting not defined';
        }
    }

    getProviderURL(provider) {
        return SERVER_API_URL + '/signin/' + provider;
    }
}
