import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {DictionaryService} from './service/dictionary.service';
import {CookieService} from 'ng2-cookies';
import {WebsocketService} from './service/websocket.service';
import {SharedCommonModule} from './shared-common.module';
import {SharedLibsModule} from './shared-libs.module';
import {ErrorComponent} from './components/error/error.component';
import {AccountService} from './service/auth/account.service';
import {LoginService} from './service/login.service';
import {Principal} from './service/auth/principal.service';
import {JhiTrackerService} from './service/tracker/tracker.service';
import {CSRFService} from './service/auth/csrf.service';
import {AuthServerProvider} from './service/auth/auth-jwt.service';
import {UserService} from './service/user.service';
import {AdminUserService} from './service/admin-user.service';
import {StrategyService} from './service/strategy.service';
import {ExchangeService} from './service/exchange.service';
import {CoinService} from './service/coin.service';
import {CurrencyService} from './service/currency.service';
import {SmartTradeService} from './service/smart-trade.service';
import {SocialService} from './components/social/social.service';
import {StateStorageService} from './service/auth/state-storage.service';
import {StrategyExecutionService} from "./service/strategy-execution.service";
import {DownloadService} from "./service/download.service";
import {PredefinedStrategyService} from "./service/predefined-strategy.service";
import {GridBotService} from "./service/grid-bot.service";
import {AdminMessageService} from "./service/admin-maintenance.service";
import {TickerService} from "./service/ticker.service";
import { Google2faService } from "./service/google2fa.service";
import { SentimentService } from "./service/sentiment.service"
import {SMSOtpService} from "./service/sms2fa.service";
import {AuditService} from "./service/audit.service";
import {LoginHistoryService} from "./service/login-history.service";
import { StrategySymbolService } from './service/strategy-symbols.service';
import { StrategyAlgorithmService } from './service/strategy-algorithm.service';
import { StrategyAutomationService } from './service/strategy-automation.service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        FormsModule,
        SharedCommonModule,
        SharedLibsModule,
        TranslateModule
    ],
    declarations: [
        ErrorComponent,
    ],
    providers: [
        CookieService,
        AccountService,
        LoginService,
        LoginHistoryService,
        Principal,
        CSRFService,
        JhiTrackerService,
        AuthServerProvider,
        SocialService,
        UserService,
        StrategyService,
        StrategyExecutionService,
        PredefinedStrategyService,
        GridBotService,
        AdminUserService,
        ExchangeService,
        CoinService,
        CurrencyService,
        SmartTradeService,
        WebsocketService,
        DictionaryService,
        StateStorageService,
        DownloadService,
        AdminMessageService,
        TickerService,
        AuditService,
        Google2faService,
        SentimentService,
        SMSOtpService,
        StrategySymbolService,
        StrategyAlgorithmService,
        StrategyAutomationService
    ],
    exports: [
        SharedCommonModule,
        ErrorComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
}
