import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {CoinModel} from "../model/asset/exchange-asset.model";

@Injectable()
export class CoinService {
    private exchangeResourceUrl = SERVER_API_URL + '/api/coin';

    constructor(private http: HttpClient) {}

    getCoinList(): Observable<CoinModel[]> {
        return this.http.get<CoinModel[]>(this.exchangeResourceUrl + "/list");
    }

}
