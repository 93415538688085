import {Component, DoCheck, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'jhi-accuracy-slider',
    template: `
        <div>
            <svg height="150" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none slice">
                <defs>
                    <linearGradient id="partialFill">
                        <stop offset="0%" stop-color="#6AEF9E" />
                        <stop [attr.offset]="percentage()" stop-color="#6AEF9E" />
                        <stop [attr.offset]="percentage()" stop-color="rgba(0, 0, 0, 0.075)" />
                        <stop offset="100%" stop-color="rgba(0, 0, 0, 0.075)" />
                    </linearGradient>
                </defs>
                <polygon fill="url(#partialFill)" points="0,100 100,0 100,100" stroke-width="0"/>
            </svg>
            <nz-slider  [nzMin]="1" [nzMax]="99" [(ngModel)]="model"></nz-slider>
        </div>
    `
})
export class AccuracySliderComponent implements DoCheck {
    @Input() model = 50;
    @Output() modelChange: EventEmitter<number> = new EventEmitter();

    ngDoCheck() {
        this.modelChange.next(this.model);
    }

    percentage() {
        return this.model.toString() + '%';
    }
}
