import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {Observable} from 'rxjs';

export class AuthInterceptor implements HttpInterceptor  {

    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');
        if (!!token && !request.url.includes('https://geolocation-db.com/json/') && !request.url.includes('authenticate')) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache'
                }
            });
        }
        return next.handle(request);
    }
}
