import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import { StrategyAlgorithmDto, StrategyAlgorithmModel } from '../model/strategy-algorithm/strategy-algorithm.model';

@Injectable()
export class StrategyAlgorithmService {
    private strategyAlgorithmResourceUrl = SERVER_API_URL + '/api/strategy-algorithms/';
 
    constructor(private http: HttpClient) {}


    findAllActiveStrategyAlgorithms(isDisabled: boolean = false, page: number = 0, size : number = 100, sort: string = 'id,desc'): Observable<any> { 
        const params = new HttpParams()
            .set('isDisabled', isDisabled.toString())
            .set('page', page.toString())
            .set('size', size.toString())
            .set('sort', sort);
      
        return this.http.get<any>(this.strategyAlgorithmResourceUrl, { params });
      }
      
    
    
    getStrategyAlgorithmById(id: any): Observable<StrategyAlgorithmDto[]> {
        return this.http.get<StrategyAlgorithmDto[]>(this.strategyAlgorithmResourceUrl+id);
    }

    editStrategyAlgorithm(strategy: StrategyAlgorithmModel, id: any): Observable<any> {
        return this.http.put(this.strategyAlgorithmResourceUrl + id, strategy);
    }

    deleteStrategyAlgorithm(id: number): Observable<any> {
        return this.http.delete(this.strategyAlgorithmResourceUrl  + id);
    }

    createStrategyAlgorithm(strategy: StrategyAlgorithmModel): Observable<StrategyAlgorithmDto> {
        return this.http.post<StrategyAlgorithmDto>(this.strategyAlgorithmResourceUrl , strategy);
    }


}