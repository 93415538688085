import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from '../../../shared/constants/app.constants';
import {Observable} from "rxjs";

@Injectable()
export class ActivateService {

    constructor(private http: HttpClient) {}

    get(key: string): Observable<any> {
        return this.http.get(SERVER_API_URL + '/api/activate', {
            params: new HttpParams().set('key', key)
        });
    }
}
