import {COOKIE_DOMAIN, SERVER_API_URL} from '../constants/app.constants';

export class ConsoleCookieService {
    public static setCookie(name: string, value: string, expireDays: number, path = '/') {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires = `expires=${d.toUTCString()}`;
        const cpath = path ? `; path=${path}` : '';
        let domain = COOKIE_DOMAIN;
        if (SERVER_API_URL.indexOf('localhost') !== -1) {
            domain = new URL(SERVER_API_URL).hostname;
        }
        document.cookie = `${name}=${value}; ${expires}; domain=${domain}${cpath};`;
    }
}
