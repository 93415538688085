<nz-layout class="outer-layout">
    <div nz-row>
        <div nz-col nzSpan="6" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="7" class="passwordContainer  p-x-2 p-x-lg-5">
            <div class="logo m-y-2">
            <h1>ETHAX TRADER</h1>
        </div>
        <div class="header">
            <h2>Reset password</h2>
        </div>
        <div>
            <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="resetPassword(validateForm.value)" autocomplete="off" class="p-t-2">
                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24" [nzErrorTip]="userErrorTpl">
                                <nz-input-group nzSize="large" nzAddOnBeforeIcon="mail">
                                    <input type="text" nz-input formControlName="email" placeholder="{{'email' | translate}}" autocomplete="email"/>
                                </nz-input-group>
                                <ng-template #userErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        {{'required' | translate}}
                                    </ng-container>
                                    <ng-container *ngIf="control.hasError('email')">
                                        {{'invalid-email' | translate}}
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-row>
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <re-captcha (resolved)="resolve($event)"></re-captcha>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-row class="m-t-4">
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <button nz-button nzBlock nzType="primary" [nzSize]="'large'" class="login-button" [disabled]="!validateForm.valid || captcha == undefined">{{'pass-req' | translate}}</button>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
            <div nz-row class="m-t-4">
                <div nz-col [nzSpan]="24">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <button nz-button nzBlock nzType="default" [nzSize]="'large'" style="background-color: transparent; color: white" [routerLink]="['/', 'login']" >{{'back-login' | translate}}</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </div>
            <img class="m-x-auto m-t-auto" src="../../../../assets/images/astronaut.png" />
        </div>
    </div>
</nz-layout>