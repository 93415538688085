import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'no_seconds' })
export class CustomDateFormatPipe implements PipeTransform {

      transform(value: string, format?: string) {
          if (undefined === value) {
              return '';
          }
          const _format = format || 'DD-MM-YYYY HH:mm';

          return moment(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(_format);

      }
}
