import {HttpHeaders} from '@angular/common/http';

export class ResponseWrapper2 {
  constructor(
    public headers: HttpHeaders,
    public json: any,
    public status: number
  ) {
  }
}
