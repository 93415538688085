import {Injectable} from '@angular/core';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';

@Injectable()
export class StateStorageService {
    constructor(private $sessionStorage: SessionStorageService,
                private $localStorage: LocalStorageService) {
    }

    storeCurrentAccount(account: any) {
        this.$sessionStorage.store('currentAccount', account);
    }

    getCurrentAccount() {
        return this.$sessionStorage.retrieve('currentAccount');
    }

    storeCurrentIP(ip: string) {
        this.$sessionStorage.store('currentIP', ip);
    }

    getCurrentIP() {
        return this.$sessionStorage.retrieve('currentIP');
    }

    storeNavHistory(history: string[]) {
        this.$localStorage.store('nav-history', history);
    }

    getNavHistory(): string[] {
        return this.$localStorage.retrieve('nav-history');
    }
}
