import {RouterModule} from '@angular/router';
import {HideLoginPipe, MaskNumberPipe, PrivateLayoutComponent} from './components/layout/private-layout.component';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf} from '@angular/core';
import {privateRoutes} from './private.route';
import {SharedCommonModule, UserRouteAccessService} from '../shared';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {NzBadgeModule} from 'ng-zorro-antd/badge';
import {NzBackTopModule} from 'ng-zorro-antd/back-top';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzPopconfirmModule} from 'ng-zorro-antd/popconfirm';
import {NzSpaceModule} from 'ng-zorro-antd/space';
import {NzRadioModule} from "ng-zorro-antd/radio";
import {NzAlertModule} from "ng-zorro-antd/alert";
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
    imports: [
        RouterModule.forRoot(privateRoutes, {useHash: true}),
        SharedCommonModule,
        NzDropDownModule,
        NzBadgeModule,
        NzBackTopModule,
        NzDividerModule,
        NzPopconfirmModule,
        NzSpaceModule,
        NzRadioModule,
        NzAlertModule,
        TranslateModule
    ],
    declarations: [
        PrivateLayoutComponent,
        MaskNumberPipe,
        HideLoginPipe,
    ],
    providers: [
        UserRouteAccessService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class PrivateModule {
    constructor(@Optional() @SkipSelf() _module: PrivateModule) {
        if (_module) {
            throw new Error('PrivateModule must be instantiated only once!');
        }
    }
}
