import {BaseEntity} from "../base-entity";

// REDDIT
export class RedditSentiments{
    constructor(
        public items?: Map<string, RedditItem[]>
    ) {
    }
}

export class RedditItem  {
    constructor(
        public sentimentMap?: Map<string, RedditSentiment>
    ) {
    }
}

export class RedditSentiment {
    constructor(
        public neg?: string,
        public neu?: string,
        public pos?: string,
        public compound?: string
    ) {
    }
}

// CRYPTO RADAR
export class CryptoRadars{
    constructor(
        public currencies?: Currency[]
    ) {
    }
}

export class Currency{
    constructor(
        public name?: string,
        public buzz?: number,
        public sentiment?: number,
        public sentimentHistory?: Sentiment[]
    ) {
    }
}

export class Sentiment{
    constructor(
        public buzz?: number,
        public sentiment?: number
    ) {
    }
}

// FNG Alternative

export class FNGAlternative{
    constructor(
        public name?: string,
        public data?: FNGAlternativeData[]
    ) {
    }
}

export class FNGAlternativeData{
    constructor(
        public value?: string,
        public value_classification?: string,
        public timestamp?: string
    ) {
    }
}


// FNG CNN

export class FNGCnn{
    constructor(
        public fear_and_greed?: CNNFearAndGreed
    ) {
    }
}

export class CNNFearAndGreed{
    constructor(
        public score?: number,
        public previous_close?: number,
        public previous_1_week?: number,
        public rating?: string,
        public timestamp?: string
    ) {
    }
}

