import {Injector} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse} from '@angular/common/http';
import {LoginService} from '../..';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';

export class AuthExpiredInterceptor implements HttpInterceptor {

    constructor(private injector: Injector) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap(
            (event: HttpEvent<any>) => {
            },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        const loginService: LoginService = this.injector.get(LoginService);
                        loginService.logout();
                    }
                }
            }
        ));
    }
}
