import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {enableProdMode} from '@angular/core';
import {EthaxAppModule} from './app.module';
import {environment} from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(EthaxAppModule)
    .then((success) => console.log(`Ethax Trader Console started`))
    .catch((err) => console.error(err));
