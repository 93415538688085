import {BaseEntity} from "../base-entity";
import {Strategy} from "../strategy/strategy.model";
import {ExchangeAccountModel} from "../exchange/exchange.model";
import {OrderModel} from "../asset/order.model";

export class StrategyExecutionModel  {
    constructor(
        public amount?: number,
        public period?: any,
        public runOnce?: boolean,
        public executionStartTime?: Date,
        public numberOfExecutions?: number,
        public timeBasedExecution?: boolean,
        public babsEnabled?: boolean,
        public notes?: string,
        public executionInterval?:string | null
    ) {
    }
}

// export class BackTesting  {
//     constructor(
//         public start?: string,
//         public end?: string,
// ) {
// }
// }

// back-test.model.ts
export class BackTestModel {
    constructor(public start: string, public end: string) {}
}


export class StrategyAutomationBacktestDto {
    constructor(
      public createdOn: string,
      public updatedOn: string,
      public id: number,
      public status: string,
      public model: BackTestModel,
      public winRatio: number,
      public winAmountRatio: number,
    ) {}
  }

export class StrategyExecution implements BaseEntity{
    constructor(
        public id?: number,
        public createdOn?: string,
        public status?: string,
        public originalAmount?: number,
        public openingOrderTrail?: StrategyExecutionTrailModel,
        public closingOrderTrail?: StrategyExecutionTrailModel,
        public runOnce?: boolean,
        public startedOn? : string,
        public stoppedOn? : string,
        public strategy?: Strategy,
        public exchangeUserId?: number,
        public exchangeAccount?: ExchangeAccountModel,
        public strategyInstanceProfitPercentagePerYear? : StrategyInstanceChartModel[],
        public totalProfitLossPercentage?: number
    ) {
    }
}

export class StrategyAutomationStatistics {
    constructor(
        public wins: number,
        public losses: number,
        public draws: number,
        public profit: number,
        public winRatio: number
    ) {}
}


export class StrategyAutomationModel implements BaseEntity {
    constructor(
      public createdOn: string,
      public updatedOn: string,
      public id: number,
      public status: string,
      public exchangeUserId: number,
      public strategy: StrategyModel
    ) {}
  }
  
  export class StrategyModel {
    constructor(
      public createdOn: string,
      public updatedOn: string,
      public id: number,
      public timeframe: string,
      public period: string,
      public quoteAmount: number,
      public takeProfitRatio: number,
      public stopLossRatio: number,
      public trailingDeviationRatio: number,
      public exchangeId: number,
    //   public strategyAlgorithm: StrategyAlgorithmModel,
      public disabled: boolean
    ) {}
  }

export class StrategyExecutionTrailModel  {
    constructor(
        public deviationRatio?: number,
        public activationPrice?: number,
    ) {
    }
}

export class StrategyExecutionInstance implements BaseEntity{
    constructor(
        public id?: number,
        public status?: string,
        public openTickerTimestamp?: string,
        public closeTickerTimestamp?: string,
        public openingOrder?: OrderModel,
        public closingOrder?: OrderModel,
        public profitLoss?: number
    ) {
    }
}

export class StrategyInstanceChartModel {
    constructor(
        public year?: number,
        public month?: number,
        public profitLossPercentage? : number
    ) {
    }
}

export class StrategyExecutionEventModel {
    constructor(
        public strategyReference: string,
        public dateExecuted: string,
        public status: string,
        public exchangeUserId: number

    ) {
    }
}

export class StrategyExecutionEventExchangeUsers{
    constructor(
        public exchangeUserIds: number[]
    ) {
    }
}
