import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {LoginHistory, LoginHistoryDTO} from "../model/user/login-history.model";
import {ErrorUtil} from "../util/error.util";
import {StateStorageService} from "./auth/state-storage.service";
import {NzMessageService} from "ng-zorro-antd/message";

@Injectable()
export class LoginHistoryService {
    private loginHistoryResourceUrl = SERVER_API_URL + '/api/login-history';

    constructor(private http: HttpClient, private stateStorageService: StateStorageService, private messageService: NzMessageService) {}

    saveLoginHistory(loginHistory: LoginHistoryDTO): Observable<any> {
        return this.http.post<LoginHistoryDTO>(this.loginHistoryResourceUrl + "/save", loginHistory);
    }

    getAllLoginHistories(userId: number): Observable<LoginHistory[]> {
        return this.http.get<LoginHistory[]>(this.loginHistoryResourceUrl + "/user/" + userId);
    }
    getLatestLoginHistory(userId: number): Observable<LoginHistory> {
        return this.http.get<LoginHistory>(this.loginHistoryResourceUrl + "/user/" + userId + "/latest");
    }

    public getIPAddress() : Observable<any> {
        return this.http.get('https://geolocation-db.com/json/');
    }

    public checkAndSaveLoginHistory(userId: number, page: string, type: string){
        let loginHistory = {ip:'',page:'',userId:0,type:''};
        this.getLatestLoginHistory(userId).subscribe(
            (result: LoginHistory) => {
                if(result.page !== page) {
                    loginHistory.userId = userId;
                    loginHistory.ip = this.stateStorageService.getCurrentIP();
                    loginHistory.page = page
                    loginHistory.type = type
                    this.saveLoginHistory(loginHistory).subscribe(
                        () => {
                        },
                        (error) => {
                            ErrorUtil.handleError(this.messageService, error);
                        }
                    );
                }
            },
            (error) => {
                ErrorUtil.handleError(this.messageService, error);
            }
        );
    }
}
