<div nz-row nzJustify="center" nzAlign="middle" class="m-t-5">
    <div nz-col nzSpan="12">
        <nz-result
                nzStatus="success"
                [nzTitle]="'register-success-title' | translate"
                [nzSubTitle]="'register-success-subtitle' | translate">
            <div nz-result-extra>
                <button nz-button nzType="primary" class="login-button" [routerLink]="['/', 'login']">{{'back-to-login' | translate}}</button>
            </div>
        </nz-result>
    </div>
</div>
