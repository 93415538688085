import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'orderBy'})

export class OrderBy implements PipeTransform {

    transform(array: Array<any>, args: string): Array<string> {
        if (array !== undefined) {
            array.sort((a: any, b: any) => {
                if (a[args] < b[args]) {
                    return -1;
                } else if (a[args] > b[args]) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return array;
        } else {
            return new Array<string>();
        }
    }
}
