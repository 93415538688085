import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {WindowRef} from './service/tracker/window.service';
import {JhiSocialComponent} from './components/social/social.component';
import {CustomDateFormatPipe} from './components/pipe/custom-date-format.pipe';
import {OrderByDate} from './components/pipe/order-by-date.pipe';
import {OrderBy} from './components/pipe/order-by.pipe';
import {JhiValueDirective} from './components/directive/value.directive';
import {SearchPipe} from './components/pipe/search.pipe';
import {JhiDisableControlDirective} from './components/directive/disable.directive';
import {PasswordStrengthBarComponent} from './components/password-strength/password-strength-bar.component';
import {RouterModule} from '@angular/router';
import {FooterComponent} from './components/footer/footer.component';
import {AccuracySliderComponent} from './components/accuracy/accuracy-slider.component';
import {JhiDateUtils} from './service/date-util.service';
import {SafePipe} from "./components/pipe/safe.pipe";
import {StyledCheckboxGroupComponent} from "./components/checkbox-group/styled-checkbox-group.component";
import {SafeHtmlPipe} from "./components/pipe/safeHtml.pipe";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzMessageModule} from "ng-zorro-antd/message";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzSelectModule} from "ng-zorro-antd/select";
import {SharedLibsModule} from "./shared-libs.module";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzTypographyModule} from "ng-zorro-antd/typography";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {TimeAgoPipe} from "./components/pipe/time-ago.pipe";
import {NgPipesModule} from "angular-pipes";
import {RoutingState} from "./service/routing-state.service";
import { TranslateModule } from '@ngx-translate/core';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';

@NgModule({
    imports: [
        SharedLibsModule,
        RouterModule,
        NzButtonModule,
        NzGridModule,
        NzIconModule,
        NzCheckboxModule,
        NzMessageModule,
        NzLayoutModule,
        NzCardModule,
        NzFormModule,
        NzInputModule,
        NzSelectModule,
        NzMenuModule,
        NzTypographyModule,
        NzToolTipModule,
        NgPipesModule,
        TranslateModule,
        NzPaginationModule 
    ],
    declarations: [
        CustomDateFormatPipe,
        TimeAgoPipe,
        SearchPipe,
        OrderBy,
        SafePipe,
        SafeHtmlPipe,
        OrderByDate,
        JhiSocialComponent,
        JhiValueDirective,
        JhiDisableControlDirective,
        PasswordStrengthBarComponent,
        FooterComponent,
        AccuracySliderComponent,
        StyledCheckboxGroupComponent
    ],
    providers: [
        JhiDateUtils,
        WindowRef,
        Title,
        RoutingState
    ],
    exports: [
        NzFormModule,
        NzGridModule,
        NzIconModule,
        NzButtonModule,
        NzCheckboxModule,
        NzMessageModule,
        NzLayoutModule,
        NzCardModule,
        NzInputModule,
        NzSelectModule,
        NzMenuModule,
        NzTypographyModule,
        NzToolTipModule,
        NgPipesModule,
        NzPaginationModule,
        SharedLibsModule,
        CustomDateFormatPipe,
        TimeAgoPipe,
        SearchPipe,
        OrderBy,
        SafePipe,
        SafeHtmlPipe,
        OrderByDate,
        JhiSocialComponent,
        JhiValueDirective,
        JhiDisableControlDirective,
        PasswordStrengthBarComponent,
        FooterComponent,
        AccuracySliderComponent,
        StyledCheckboxGroupComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedCommonModule {
}
