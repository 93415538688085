/**
 * Represents a trading instrument e.g. BTC/USD
 * the base is BTC and the counter is USD
 */
export class CurrencyPair {
    public _base: string;
    public _counter: string;

    constructor(private name: string) {
        const parts = name?.split('/');
        if (parts) {
            this._base = parts[0];
            this._counter = parts[1];
        }
    }

    public market = () => this._counter;
    public token = () => this._base;
    public tradingViewPair = () => { return `${this._base}${this._counter}` }
    public instrument = () => { return `${this._base}/${this._counter}` }
}
