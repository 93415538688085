import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {User, UserRequestModel} from '../model/user/user.model';
import {createRequestOption2} from '../model/request-util';
import {Notification, NotificationRequest} from '../model/notification.model';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {ExchangeAccountModel} from "../model/exchange/exchange.model";
import {AdminMessage} from "../model/admin/admin-message.model";

@Injectable()
export class AdminMessageService {
    private resourceUrl = SERVER_API_URL + '/api/admin-maintenance';

    constructor(private http: HttpClient) {
    }

    getAllAdminMessages(): Observable<AdminMessage[]> {
        return this.http.get<AdminMessage[]>(this.resourceUrl + '/admin-messages');
    }

    getAllActiveAdminMessages(): Observable<AdminMessage[]> {
        return this.http.get<AdminMessage[]>(this.resourceUrl + '/admin-messages/active');
    }

    createAdminMessage(adminMessage: AdminMessage): Observable<any> {
        return this.http.post(this.resourceUrl + '/admin-messages', adminMessage);
    }

    deleteAdminMessage(id: number): Observable<any> {
        return this.http.delete(this.resourceUrl + '/admin-messages/'+id);
    }
}
