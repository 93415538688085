import * as moment from 'moment';
import {unitOfTime} from 'moment';

export class MomentUtil {

    static convertFromUtcToLocal(date: Date): Date {
        return moment.utc(date).toDate();
    }

    static today() {
        return moment();
    }

    static inAdvance(numberOfDays: number) {
        return moment().add(numberOfDays, 'days');
    }

    static format(date: Date): string {
        return moment.utc(date).format('DD-MM-YYYY HH:mm:ss')
    }

    static formatWithoutHours(date: Date): string {
        return moment.utc(date).format('YYYY-MM-DD')
    }

    static isAfter(startDate: Date, endDate: Date) {
        return moment(endDate).isAfter(startDate, 'day');
    }

    static isBefore(startDate: Date, endDate: Date) {
        return moment(endDate).isBefore(startDate, 'day')
    }

    static diff(startDate: Date, endDate: Date, _unitOfTime: unitOfTime.Diff) {
        return moment(endDate).diff(moment(startDate), _unitOfTime);
    }
}
