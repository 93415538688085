import {CUSTOM_ELEMENTS_SCHEMA, NgModule, Optional, SkipSelf} from '@angular/core';
import {RouterModule} from '@angular/router';
import {
    ActivateComponent,
    ActivateService,
    PasswordResetFinishComponent,
    PasswordResetFinishService,
    PasswordResetInitComponent,
    PasswordResetInitService,
    Register,
    RegisterComponent, ValidateGoogle2FACode,
} from './index';
import {RecaptchaFormsModule, RecaptchaModule} from 'ng-recaptcha';
import {JhiLoginComponent} from './login/login.component';
import {SharedCommonModule} from '../../shared';
import {LogoutComponent} from './logout/logout.component';
import {CookieService} from 'ng2-cookies';
import {SocialRegisterComponent} from './social/social-register.component';
import {SocialAuthComponent} from './social/social-auth.component';
import {RegisterSuccessComponent} from './register/register-success.component';
import {PasswordResetInitSuccessComponent} from './password-reset/init/password-reset-init-success.component';
import {PasswordResetFinishSuccessComponent} from './password-reset/finish/password-reset-finish-success.component';
import {accountState} from "./account.route";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzResultModule} from "ng-zorro-antd/result";
import {SocialDispatchComponent} from "./social/social-dispatch.component";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        RouterModule.forRoot(accountState, {useHash: true}),
        RecaptchaModule,
        RecaptchaFormsModule,
        SharedCommonModule,
        NzAlertModule,
        NzDividerModule,
        NzResultModule,
        NzModalModule,
        NzBreadCrumbModule,
        NzInputNumberModule,
        TranslateModule
    ],
    declarations: [
        JhiLoginComponent,
        LogoutComponent,
        SocialRegisterComponent,
        SocialAuthComponent,
        SocialDispatchComponent,
        ActivateComponent,
        RegisterComponent,
        RegisterSuccessComponent,
        PasswordResetInitComponent,
        PasswordResetInitSuccessComponent,
        PasswordResetFinishComponent,
        PasswordResetFinishSuccessComponent,
        ValidateGoogle2FACode
    ],
    providers: [
        CookieService,
        Register,
        ActivateService,
        PasswordResetInitService,
        PasswordResetFinishService
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountModule {
    constructor(@Optional() @SkipSelf() _module: AccountModule) {
        if (_module) {
            throw new Error('AccountModule must be instantiated only once!');
        }
    }
}
