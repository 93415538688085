import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { SERVER_API_URL } from '../../constants/app.constants';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthServerProvider {
    private resourceUrl = SERVER_API_URL + '/api/authenticate';


    private readonly TIMEOUT_PERIOD = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
    private timeoutId: any;


    constructor(


        private router: Router,

        private http: HttpClient,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService) { }

    getToken() {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    }

    startSessionTimer(): void {
        this.resetSessionTimer();

        // Listen for user activity
        window.addEventListener('mousemove', this.resetSessionTimer.bind(this));
        window.addEventListener('mousedown', this.resetSessionTimer.bind(this));
        window.addEventListener('keypress', this.resetSessionTimer.bind(this));
        window.addEventListener('touchmove', this.resetSessionTimer.bind(this));
    }

    resetSessionTimer(): void {
        // Clear the existing timeout
        clearTimeout(this.timeoutId);

        // Set a new timeout
        this.timeoutId = setTimeout(() => {
            this.logout().subscribe(() => {
                this.router.navigate(['/login']);
            });
        }, this.TIMEOUT_PERIOD);
    }


    login(credentials): Observable<any> {
        const data = {
            username: credentials.username,
            password: credentials.password,
            rememberMe: credentials.rememberMe
        };
        return this.http.post(this.resourceUrl, data, { observe: 'body' }).pipe(
            map((json) => this.authenticateSuccess(json, credentials))
        );
    }

    authenticateSuccess(json, credentials) {
        const bearerToken = json && json.id_token;
        if (bearerToken) {
            const jwt = json.id_token;
            this.storeAuthenticationToken(jwt, credentials.rememberMe);
            return jwt;
        }
    }


    loginWithToken(jwt, rememberMe) {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }
    }

    storeAuthenticationToken(jwt, rememberMe) {
        if (rememberMe) {
            this.$localStorage.store('authenticationToken', jwt);
        } else {
            this.$sessionStorage.store('authenticationToken', jwt);
        }
    }

    logout(): Observable<any> {
        return new Observable((observer) => {
            this.$localStorage.clear('authenticationToken');
            this.$sessionStorage.clear('authenticationToken');
            observer.complete();
        });
    }
}
