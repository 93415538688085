import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {Indicators, Strategy, StrategyModel} from "../model/strategy/strategy.model";
import {ExchangeAccountModelDTO} from "../model/exchange/exchange.model";
import {
    StrategyExecution, StrategyExecutionEventExchangeUsers, StrategyExecutionEventModel,
    StrategyExecutionInstance,
    StrategyExecutionModel
} from "../model/strategy-execution/strategy-execution.model";

@Injectable()
export class StrategyExecutionService {
    private strategyExecutionResourceUrl = SERVER_API_URL + '/api/strategy-executions';
    private strategyExecutionEventResourceUrl = SERVER_API_URL + '/api/strategy-executions-events';

    constructor(private http: HttpClient) {}


    getAllStrategyExecution(id: any): Observable<StrategyExecution[]> {
        return this.http.get<StrategyExecution[]>(this.strategyExecutionResourceUrl + "/account/" + id);
    }

    // createStrategyExecution(strategyExecution: StrategyExecutionModel, accountId: number, strategyId: number): Observable<any> {
    //     return this.http.post<StrategyExecutionModel>(this.strategyExecutionResourceUrl + '/account/' + accountId +"/strategies/" + strategyId,  strategyExecution);
    // }

    createStrategyExecution(strategyExecution: StrategyExecutionModel, accountId: number, strategyId: number): Observable<any> {
        return this.http.post<StrategyExecutionModel>(this.strategyExecutionResourceUrl + '/accounts/' + accountId +"/strategies/" + strategyId, strategyExecution);
    }

    startStrategyExecution(id: any): Observable<any> {
        return this.http.put(this.strategyExecutionResourceUrl + '/' + id + "/start", null);
    }

    stopStrategyExecution(id: any): Observable<any> {
        return this.http.put(this.strategyExecutionResourceUrl + '/' + id + "/stop", null);
    }

    getStrategyExecution(id: any): Observable<StrategyExecution> {
        return this.http.get<StrategyExecution>(this.strategyExecutionResourceUrl + '/account/strategies/'+ id);
    }

    getAllStrategyExecutionEvents(): Observable<StrategyExecutionEventModel[]> {
        return this.http.get<StrategyExecutionEventModel[]>(this.strategyExecutionEventResourceUrl + '/');
    }

    getAllStrategyExecutionEventsByExchangeUserId(strategyExecutionEventExchangeUsers : StrategyExecutionEventExchangeUsers): Observable<StrategyExecutionEventModel[]> {
        return this.http.post<StrategyExecutionEventModel[]>(this.strategyExecutionEventResourceUrl + '/exchange-user-ids', strategyExecutionEventExchangeUsers );
    }

    createStrategyExecutionEvent(strategyExecutionEventModel: StrategyExecutionEventModel): Observable<any> {
        return this.http.post<StrategyExecutionEventModel>(this.strategyExecutionEventResourceUrl + '/',  strategyExecutionEventModel);
    }

}
