<div>
    <div class="row">
        <div class="col-md-8 offset-md-2">
            <h1 *ngIf="success">{{ 'social.register.title' | translate}}</h1>
            <h1 *ngIf="error">{{ 'social.register.errorTitle' | translate }}</h1>

            <div class="alert alert-success" *ngIf="success">
                <strong>{{ 'social.register.messages.success' | translate }}</strong>.
            </div>

            <div class="alert alert-danger" *ngIf="error">
                {{getErrorKey() | translate}}
            </div>

            <jhi-social *ngIf="success" provider="{{ provider }}"></jhi-social>
            <br/>
        </div>
    </div>
</div>
