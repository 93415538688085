<nz-checkbox-wrapper>
    <div nz-row>
        <div nz-col nzSpan="8" nzXs="24" nzSm="24" nzMd="24" nzLg="12" nzXl="8" *ngFor="let option of options; trackBy:trackByOption" [nzSpan]="option.span || 24" >
            <label nz-checkbox
                   class="styled-checkbox-group-item"
                   [nzDisabled]="option.disabled || nzDisabled"
                   [(nzChecked)]="option.checked"
                   (nzCheckedChange)="onOptionChange()">
                <ng-container [ngTemplateOutlet]="nzLabel" [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
            </label>
        </div>
    </div>
</nz-checkbox-wrapper>
