import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { Location } from "@angular/common";
import {
  CurrencyPairCurrentPriceModel,
  ExchangeAccountModel,
  ExchangeAssetModel,
  LoginService,
  MomentUtil,
  Principal,
  StateStorageService,
  User,
  UserService,
  WalletHistoryAssets,
  WalletHistoryDto,
  WalletModel,
} from "../../../shared";
import { NavigationEnd, Router } from "@angular/router";
import { Notification } from "../../../shared/model/notification.model";
import { ErrorUtil } from "../../../shared/util/error.util";
import { JhiEventManager } from "../../../shared/service/event-manager.service";
import { ConfigurationService } from "../../../shared/service/configuration.service";
import { Subscription, interval, timer } from "rxjs";
import { JhiEventWithContent } from "../../../shared/model/event-with-content.model";
import { WebsocketService } from "../../../shared/service/websocket.service";
import { NzMessageService } from "ng-zorro-antd/message";
import { TranslateService } from "@ngx-translate/core";
import { en_US, NzI18nService, ro_RO } from "ng-zorro-antd/i18n";
import { AdminMessage } from "../../../shared/model/admin/admin-message.model";
import { AdminMessageService } from "../../../shared/service/admin-maintenance.service";
import { ExchangeService } from "../../../shared/service/exchange.service";
import { map } from "rxjs/operators";
import { CurrencyService } from "../../../shared/service/currency.service";
import { AssetAwareComponent } from "../../../shared/components/asset-aware.component";
import { TickerService } from "../../../shared/service/ticker.service";
import {
  NzNotificationPlacement,
  NzNotificationService,
} from "ng-zorro-antd/notification";
import { HttpClient } from "@angular/common/http";
import { ExchangeAccountService } from "../../../shared/service/exchange-account.service";
import { EthaxAppModule } from "../../../app.module";

import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeAr from "@angular/common/locales/ar";
import localeZh from "@angular/common/locales/zh";
import localeCs from "@angular/common/locales/cs";
import localeEt from "@angular/common/locales/et";
import localeFr from "@angular/common/locales/fr";
import localeDe from "@angular/common/locales/de";
import localeJa from "@angular/common/locales/ja";
import localeKo from "@angular/common/locales/ko";
import localePt from "@angular/common/locales/pt";
import localeRo from "@angular/common/locales/ro";
import localeRu from "@angular/common/locales/ru";
import localeEs from "@angular/common/locales/es";
import localeTr from "@angular/common/locales/tr";

registerLocaleData(localeEn);
registerLocaleData(localeAr);
registerLocaleData(localeZh);
registerLocaleData(localeCs);
registerLocaleData(localeEt);
registerLocaleData(localeFr);
registerLocaleData(localeDe);
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localePt);
registerLocaleData(localeRo);
registerLocaleData(localeRu);
registerLocaleData(localeEs);
registerLocaleData(localeTr);

@Component({
  templateUrl: "./private-layout.component.html",
  styleUrls: ["./private-layout.component.scss"],
})
export class PrivateLayoutComponent
  extends AssetAwareComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  account: User;
  serverConnected = true;
  notifications: Notification[] = [];
  initialLoadingDone: boolean;
  language: string = "en";
  subscriptions: Subscription[];
  btc_usd: number = 0.0;
  eth_usd: number = 0.0;
  ethax_usd: number = 0.0;
  adminMessages = [];
  accountType = "";
  exchangeAccountList: ExchangeAccountModel[];
  assets: ExchangeAssetModel[] = [];
  usdTotalAssetValue = 0;
  btcTotalAssetValue = 0;
  isCollapsed: boolean;
  maskValues = false;
  hideLogin: boolean = false;
  openMenu = false;

  latestTickers: CurrencyPairCurrentPriceModel[];
  previousDayTickers: CurrencyPairCurrentPriceModel[];

  ipAddress = "";

  routeStates = [
    { route: "/exchange/connect", name: "My Exchanges" },
    { route: "/dashboard", name: "My Portfolio" },
    { route: "/sentiments", name: "Market Sentiment" },
    { route: "/crypto-news", name: "Crypto News" },
    { route: "/market-place", name: "Market Place" },
    { route: "/customer-support", name: "Customer Support" },
    { route: "/user/account", name: "Settings" },
    { route: "/subscription", name: "Subscription Plans" },
    { route: "/payment-checkout", name: "Payment Checkout" },
    { route: "/payment-success", name: "Payment Success" },
    { route: "/payment-failure", name: "Payment Failure" },
    { route: "/reports", name: "Event Logs" },
    { route: "/wise-trades", name: "Wise Trade" },
    { route: "/automated-trading", name: "Automated Trading" },
    { route: "/automated-trading/add", name: "Automated Trading" },
    { route: "/exchange/asset", name: "Exchange info" },
    { route: "/exchange/new", name: "Exchange info" },
    { route: "/admin/maintenance", name: "Maintenance Board" },
    { route: "/admin/strategies", name: "My Strategies" },
    { route: "/admin/add-strategy", name: "Strategy Info" },
    { route: "/admin/strategies-algorithm", name: "Strategy Algorithms" },
    { route: "/admin/users", name: "Users Panel" },
    { route: "/admin/customer-support", name: "Customer Support" },
    { route: "/admin/strategies-symbol-split", name: "Strategy Symbols" },
    { route: "/admin/subscriptions", name: "Admin Subscriptions" },
  ];

  routeState = "";

  private newExchangeAccountAddedSubscription: Subscription;
  totalUSDPortfolioValue: number;
  totalBTCPortfolioValue: number;
  walletHistoryAssets: WalletHistoryAssets[] = [];
  walletUSD24Change: number;
  walletBTC24Change: number;
  isVisible = false;
  isFetchData: boolean;

  constructor(
    protected loginService: LoginService,
    protected router: Router,
    protected userService: UserService,
    protected location: Location,
    protected messageService: NzMessageService,
    protected stateStorageService: StateStorageService,
    protected eventManager: JhiEventManager,
    protected configService: ConfigurationService,
    protected websocketService: WebsocketService,
    protected translateService: TranslateService,
    private message: NzMessageService,
    protected i18n: NzI18nService,
    public principal: Principal,
    protected adminMessageService: AdminMessageService,
    protected exchangeService: ExchangeService,
    protected currencyService: CurrencyService,
    protected tickerService: TickerService,
    private exchangeAccountService: ExchangeAccountService, // Add this line
    protected notification: NzNotificationService,
    private http: HttpClient,
    private modalService: NzModalService
  ) {
    super(
      messageService,
      exchangeService,
      stateStorageService,
      principal,
      currencyService,
      tickerService
    );
    this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        const url = data.urlAfterRedirects.split("?")[0];
        this.routeState = this.routeStates.find((x) => url == x.route)?.name;
      }
    });
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.account.enable2FA) {
        this.show2FactorAuth();
      }
    }, 3000);
    this.isCollapsed = true;
  }
  // High Volume Alert Threshold
  VOLUME_ALERT_THRESHOLD = 1.5; // 150% of the previous volume

  // Price Spike Alert Threshold (in percentage)
  PRICE_SPIKE_THRESHOLD = 5; // 5%

  // Biggest currency pairs
  BIGGEST_CURRENCY_PAIRS = [
    "BTC/USDT",
    "ETH/USDT",
    "XRP/USDT",
    "LTC/USDT",
    "BCH/USDT",
  ];

  @ViewChild("modalFooter")
  private modalFooter: TemplateRef<any>;

  @ViewChild("2fContent")
  private twoFContent: TemplateRef<any>;

  async ngOnInit() {

    this.checkScreenWidth();
    // localhost is here just to create a legal URL value
    // const url = new URL(`http://localhost${this.router.url}`).pathname;
    // this.routeState = this.routeStates.find(x => url.startsWith(x.route))?.name;

    this.account = await this.principal.identity(false);

    this.accountType = this.account.enablePaperTrading ? "PAPER_TRADE" : "LIVE";
    this.initialLoadingDone = false;

    this.initNotifications();
    this.subscriptions = [];
    this.subscriptions.push(
      this.eventManager.subscribe("ws.connection.gained", () => {
        this.serverConnected = true;
      }),
      this.eventManager.subscribe("ws.connection.lost", () => {
        this.serverConnected = false;
      }),
      this.eventManager.subscribe("reload-account", (newAccount) => {
        this.account = newAccount;
        this.stateStorageService.storeCurrentAccount(this.account);
        this.enablePaperTrade = this.account.enablePaperTrading;
        this.updatePricesAndBalances().then();
      })
    );

    this.websocketService
      .listen("notification_client", WebsocketService.TOPIC_NOTIFICATIONS)
      .then((websocketClient) => {
        websocketClient.subscribe((result: Notification) => {
          this.messageService.info(result.message);
          this.initNotifications();
        });
      });

    // if (this.account && this.account.currentLanguage) {
    //     this.language = this.account.currentLanguage;
    // } else {
    //     this.language = 'en';
    //     // Set default language for user
    //     this.updateUserLanguage(this.language);
    // }
    // this.translateService.use(this.language);
    // const nzLang = EthaxAppModule.languageCode;
    // let localeCode = en_US;  // default locale code

    // if (this.language in nzLang) {
    //     localeCode = nzLang[this.language];
    // }

    // this.i18n.setLocale(localeCode);
    // Check if there's a saved language in sessionStorage
    const savedLanguage = sessionStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      this.language = savedLanguage;
    }

    this.applyLanguage();
    this.initialLoadingDone = true;

    this.adminMessageService.getAllActiveAdminMessages().subscribe(
      (result: Array<AdminMessage>) => {
        this.adminMessages = result;
      },
      (error) => {
        ErrorUtil.handleError(this.messageService, error);
      }
    );

    // this.subscriptions.push(
    //     timer(0, 10000).pipe(
    //         map(() => {
    this.updatePricesAndBalances().then();
    //         })
    //     ).subscribe()
    // );

    // this.getIPAddress();

    // Subscribe to the newExchangeAccountAdded event from the exchange account service
    this.newExchangeAccountAddedSubscription =
      this.exchangeAccountService.newExchangeAccountAdded$.subscribe(() => {
        this.refreshExchangeAccountList();
      });

    // Add these lines at the end of ngOnInit()
    this.calculatePortfolioValues();

    // Set up an interval to recalculate the values every 15 seconds
    // this.subscriptions.push(
    //     interval(15000).subscribe(() => {
    //         this.calculatePortfolioValues();
    //     })
    // );
  }

  show2FactorAuth() {
    if (localStorage.getItem("is_2f_showed") != "true") {
      const modal = this.modalService.create({
        nzTitle: "Warning",
        nzClosable: true,
        //nzFooter: this.modalFooter,
        nzContent: this.twoFContent,
        nzCentered: true,
        nzOkText: "OK",
        nzOkType: "primary",
        nzOnOk: () => this.navigateToSettings(),
        nzCancelText: "Cancel",
        nzOnCancel: () => localStorage.setItem("is_2f_showed", "true"),
      });
    }
  }

  navigateToSettings() {
    this.router.navigate(["/user/account"]);
    localStorage.setItem("is_2f_showed", "true");
  }

  applyLanguage() {
    this.translateService.use(this.language);

    const nzLang = EthaxAppModule.languageCode;
    let localeCode = en_US;

    if (this.language in nzLang) {
      localeCode = nzLang[this.language];
    }

    this.i18n.setLocale(localeCode);

    sessionStorage.setItem("selectedLanguage", this.language);
  }

  onLanguageChange(newLanguage: string) {
    this.applyLanguage();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any): void {
    this.checkScreenWidth();
  }

  private checkScreenWidth(): void {
    if (window.innerWidth < 870) {
      this.isCollapsed = true;
    } else {
      this.isCollapsed = false;
    }
  }

  toggleMasking() {
    this.maskValues = !this.maskValues;
  }

  async calculatePortfolioValues() {
    // Use temporary variables for new values
    let newTotalUSDPortfolioValue = 0;
    let newTotalBTCPortfolioValue = 0;
    this.isFetchData = true;
    const prices = await this.fetchExternalBtcEthEthaxPrices();

    this.btc_usd = prices.btc_usd;

    this.exchangeAccountList = await this.getConnectedExchangeAccounts();
    this.assets = await this.getAssetBalanceWithUsdPrices(
      this.exchangeAccountList
    );
    this.assets.sort((a1, a2) => a1.token.localeCompare(a2.token));
    this.assets.forEach((asset) => {
      asset.total = asset.amount * asset.price;
      newTotalUSDPortfolioValue += asset.total;
    });
    let results: any = [];
    if (
      this.exchangeAccountList &&
      this.exchangeAccountList.length > 0 &&
      this.exchangeAccountList[0].exchange
    ) {
      results = await Promise.all([
        this.tickerService
          .getLatestTickers(this.exchangeAccountList[0].exchange.exchangeName)
          .toPromise(),
        this.tickerService
          .getTickers24h(this.exchangeAccountList[0].exchange.exchangeName)
          .toPromise(),
      ]);
    }
    if (results.length > 0) {
      this.latestTickers = results[0];
      this.tickerService.updateLatestTickers(this.latestTickers);
    }
    if (results.length > 1) {
      this.previousDayTickers = results[1];
    }
    this.calculateChanges(
      this.assets,
      this.latestTickers,
      this.previousDayTickers,
      ["BUSD", "USDT", "USDC", "USD"]
    );
    newTotalBTCPortfolioValue = newTotalUSDPortfolioValue / prices.btc_usd;
    this.getWalletHistories(this.assets, this.exchangeAccountList, 7);
    this.isFetchData = false;
    // Only update the component properties if the new values are valid
    if (newTotalUSDPortfolioValue > 0 && newTotalBTCPortfolioValue > 0) {
      this.totalUSDPortfolioValue = newTotalUSDPortfolioValue;
      this.totalBTCPortfolioValue = newTotalBTCPortfolioValue;
    }

    // Filter tickers to include only the 5 biggest currency pairs
    const filteredLatestTickers = this.latestTickers?.filter((ticker) =>
      this.BIGGEST_CURRENCY_PAIRS?.includes(ticker.instrument)
    );
    const filteredPrevDayTickers = this.previousDayTickers?.filter((ticker) =>
      this.BIGGEST_CURRENCY_PAIRS?.includes(ticker.instrument)
    );

    // Iterate through the filtered tickers and generate alerts
    filteredLatestTickers?.forEach((ticker, index) => {
      // For simplicity, assuming that the tickers are ordered in the same way for latest and previous day
      const prevTicker = filteredPrevDayTickers[index];

      // High Volume Alert
      if (ticker.volume > prevTicker.volume * this.VOLUME_ALERT_THRESHOLD) {
        const highVolumeAlertTitle = "High Volume Alert";
        const highVolumeAlertMessage = `High Volume Alert for ${ticker.instrument}: Previous Volume = ${prevTicker.volume}, Current Volume = ${ticker.volume}`;
        this.showNotification(
          highVolumeAlertTitle,
          highVolumeAlertMessage,
          ticker.instrument,
          0,
          ticker.volume
        );
      }

      // Price Spike Alert
      const priceChange = ((ticker.last - ticker.open) / ticker.open) * 100;
      if (Math.abs(priceChange) >= this.PRICE_SPIKE_THRESHOLD) {
        const priceSpikeAlertTitle = "Price Spike Alert";
        const priceSpikeAlertMessage = `Price Spike Alert for ${
          ticker.instrument
        }: Open Price = ${ticker.open}, Last Price = ${
          ticker.last
        }, Change = ${priceChange.toFixed(2)}%`;
        this.showNotification(
          priceSpikeAlertTitle,
          priceSpikeAlertMessage,
          ticker.instrument,
          priceChange,
          0
        );
      }
    });
  }

  showNotification(
    title: string,
    message: string,
    pair: string,
    percentChange: number = 0,
    volumeChange: number = 0
  ) {
    if (percentChange) {
      const storedPercentChange = parseFloat(
        sessionStorage.getItem(pair + "-percentChange")
      );

      if (!storedPercentChange || percentChange >= 2 * storedPercentChange) {
        this.notification.create("warning", title, message, {
          nzDuration: 6000,
        });
        sessionStorage.setItem(
          pair + "-percentChange",
          percentChange.toString()
        );
      }
    }

    if (volumeChange) {
      const storedVolumeChange = parseFloat(
        sessionStorage.getItem(pair + "-volumeChange")
      );

      if (!storedVolumeChange || volumeChange >= 2 * storedVolumeChange) {
        this.notification.create("warning", title, message, {
          nzDuration: 6000,
        });
        sessionStorage.setItem(pair + "-volumeChange", volumeChange.toString());
      }
    }
  }

  calculateChanges(
    assets: ExchangeAssetModel[],
    latestTickers: CurrencyPairCurrentPriceModel[],
    previousDayTickers: CurrencyPairCurrentPriceModel[],
    currencyPairs: string[]
  ) {
    assets.forEach((asset) => {
      let change1 = 0,
        change2 = 0;
      for (let i = 0; i < currencyPairs.length; i++) {
        const currencyPair = currencyPairs[i];
        const latestTicker = latestTickers.find(
          (ticker) =>
            ticker.instrument.split("/")[0] === asset.token &&
            ticker.instrument.split("/")[1] === currencyPair
        );
        if (latestTicker) {
          change1 = latestTicker.last;
          break;
        }
      }
      for (let i = 0; i < currencyPairs.length; i++) {
        const currencyPair = currencyPairs[i];
        const previousTicker = previousDayTickers.find(
          (ticker) =>
            ticker.instrument.split("/")[0] === asset.token &&
            ticker.instrument.split("/")[1] === currencyPair
        );
        if (previousTicker) {
          change2 = previousTicker.last;
          break;
        }
      }
      asset.change = ((change1 - change2) / change2) * 100 || 0;
    });
  }

  getWalletHistories(
    assetData: ExchangeAssetModel[],
    exchangeAccounts: ExchangeAccountModel[],
    day: number
  ) {
    this.walletHistoryAssets = [];
    // loop through every account connected
    exchangeAccounts.forEach((exchangeAccount) => {
      //get daily histories of the per account
      this.exchangeService
        .getExchangeAccountDailyHistory(exchangeAccount.id, day)
        .subscribe(
          (result: Array<WalletHistoryDto>) => {
            //loop result to map on walletHistoryAssets
            result.forEach((item) => {
              let balances = new Map(
                Object.entries(item.tradingWallet.balances)
              );
              let walletHistoryAsset = new WalletHistoryAssets(
                item.bucket,
                0,
                0
              );
              let updateBucketItem: WalletHistoryAssets =
                this.walletHistoryAssets.find((x) => x.bucket == item.bucket);

              if (updateBucketItem != undefined) {
                let index = this.walletHistoryAssets.indexOf(updateBucketItem);
                balances.forEach((value: WalletModel, key: string) => {
                  let asset = assetData.find((t) => t.token == key);
                  if (asset) {
                    updateBucketItem.totalUSD += value.total * asset.price;
                    updateBucketItem.totalBTC +=
                      (value.total * asset.price) / this.btc_usd;
                  }
                });
                this.walletHistoryAssets[index] = updateBucketItem;
              } else {
                balances.forEach((value: WalletModel, key: string) => {
                  let asset = assetData.find((t) => t.token == key);
                  if (asset) {
                    walletHistoryAsset.bucket = item.bucket;
                    walletHistoryAsset.totalUSD += value.total * asset.price;
                    walletHistoryAsset.totalBTC +=
                      (value.total * asset.price) / this.btc_usd;
                  }
                });
                this.walletHistoryAssets.push(walletHistoryAsset);
              }
            });

            if (day == 1) {
              let diff =
                this.totalUSDPortfolioValue -
                this.walletHistoryAssets[0].totalUSD;
              this.walletUSD24Change =
                (diff / this.totalUSDPortfolioValue) * 100;

              let diffBTC =
                this.totalBTCPortfolioValue -
                this.walletHistoryAssets[0].totalBTC;
              this.walletBTC24Change =
                (diffBTC / this.totalBTCPortfolioValue) * 100;
            }

            this.chartInit();
          },
          (error) => {
            ErrorUtil.handleError(this.messageService, error);
          }
        );
    });
  }
  chartInit() {
    // throw new Error('Method not implemented.');
  }
  //for mobile menu

  openTopMenu() {
    this.openMenu = !this.openMenu;
    this.calculatePortfolioValues();
  }
  openSideMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  getIPAddress() {
    this.http.get("https://geolocation-db.com/json/").subscribe((res: any) => {
      this.ipAddress = res.IPv4;
    });
  }
  switchLanguage(lang: string) {
    this.language = lang;
    this.translateService.use(this.language);
    const nzLang = EthaxAppModule.languageCode;
    this.i18n.setLocale(
      nzLang && nzLang[this.language] ? nzLang[this.language] : en_US
    );
    this.eventManager.broadcast(
      new JhiEventWithContent("languageChanged", this.language)
    );
    this.updateUserLanguage(this.language);
  }

  updateUserLanguage(lang: string) {
    const user: User = this.stateStorageService.getCurrentAccount();
    user.currentLanguage = lang;
    this.userService.updateProfile(user).subscribe(
      (result) => {
        this.stateStorageService.storeCurrentAccount(result);
      },
      (err) => {}
    );
  }

  logout() {
    this.websocketService.destroy();
    this.loginService.logout();
    this.router.navigate([""]);
  }

  gotoDashboard() {
    this.router.navigate(["/dashboard"]);
  }

  toggleLoginVisibility() {
    this.hideLogin = !this.hideLogin;
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.forEach((sub) => sub && sub.unsubscribe());
    }
    this.newExchangeAccountAddedSubscription.unsubscribe();
  }

  async refreshExchangeAccountList(): Promise<void> {
    this.exchangeAccountList = await this.getConnectedExchangeAccounts();
  }

  // this is called periodically
  async updatePricesAndBalances() {
    const prices = await this.fetchExternalBtcEthEthaxPrices();
    this.btc_usd = prices.btc_usd;
    this.eth_usd = prices.eth_usd;
    this.ethax_usd = prices.ethax_usd;

    this.exchangeAccountList = await this.getConnectedExchangeAccounts();
    // const assets = await this.getAssetBalanceWithUsdPrices(this.exchangeAccountList);
    // assets.forEach(asset => {
    //     usdTotalAssetValue += asset.total;
    // });
    let btcValue: any = 0;
    // btcValue = await this.calculateTotalAccountBtcAsset(btcValue);
    this.usdTotalAssetValue = btcValue * prices.btc_usd;
    this.btcTotalAssetValue = btcValue;
  }

  // async calculateTotalAccountBtcAsset(btcValue: any) {

  //     const assets = await this.getConnectedExchangeAccounts();

  //     assets.forEach((asset, index) => {
  //         const availableBtc = asset.tradingWallet.balances['BTC'].available;
  //         btcValue += availableBtc;
  //     });

  //     return btcValue;
  // }

  createBasicNotification(position: NzNotificationPlacement): void {
    this.notification.blank(
      "Notification Title",
      "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
      { nzPlacement: position }
    );
  }

  private initNotifications() {
    this.userService.getNotifications().subscribe(
      (result: Array<Notification>) => {
        this.notifications = result;
        this.notifications.forEach((not) => {
          not.createdDate = MomentUtil.convertFromUtcToLocal(not.createdDate);
        });

        this.notifications.sort((a, b) => {
          return (
            new Date(b.createdDate).getTime() -
            new Date(a.createdDate).getTime()
          );
        });
      },
      (error) => {
        ErrorUtil.handleError(this.messageService, error);
      }
    );
  }

  // Method to count unread notifications
  get unreadNotificationsCount(): number {
    return this.notifications.filter((notification) => !notification.read)
      .length;
  }

  markAsRead(notification: Notification) {
    this.userService.markAsRead(notification.id).subscribe(
      () => {
        notification.read = true;
      },
      () => {}
    );
  }

  // Method to mark all notifications as read
  markAllAsRead() {
    const unreadNotifications = this.notifications.filter(
      (notification) => !notification.read
    );

    unreadNotifications.forEach((notification) => {
      this.userService.markAsRead(notification.id).subscribe(
        () => {
          notification.read = true;
        },
        () => {
          // Handle any error that might occur while marking a notification as read.
          console.error(
            `Failed to mark notification with id ${notification.id} as read.`
          );
        }
      );
    });
  }

  CryptoUrl() {
    window.open("https://cryptonews.com", "blank");
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { log } from "console";
import { NzModalService } from "ng-zorro-antd/modal";
import { Model } from "echarts";

@Pipe({
  name: "maskNumber",
})
export class MaskNumberPipe implements PipeTransform {
  transform(value: any, length: number): string {
    return value ? "*".repeat(length) : value;
  }
}

@Pipe({ name: "hideLoginPipe" })
export class HideLoginPipe implements PipeTransform {
  transform(value: string, hide: boolean): string {
    if (hide) {
      return "*".repeat(value.length);
    }
    return value;
  }
}
