import {AccountModule} from './account/account.module';
import {SharedCommonModule, UserRouteAccessService} from '../shared';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {PublicLayoutComponent} from './components/layout/public-layout.component';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {publicRoutes} from './public.route';
import {NzAlertModule} from "ng-zorro-antd/alert";
import {RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {GOOGLE_SITE_KEY} from "../shared/constants/app.constants";
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        RouterModule.forRoot(publicRoutes, {useHash: true}),
        CommonModule,
        AccountModule,
        SharedCommonModule,
        NzAlertModule,
        RecaptchaModule,
        HttpClientModule,
        TranslateModule
    ],
    declarations: [
        PublicLayoutComponent,
    ],
    providers: [
        UserRouteAccessService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: { siteKey: GOOGLE_SITE_KEY } as RecaptchaSettings,
        },
    ]
})
export class PublicModule {
    constructor(@Optional() @SkipSelf() _module: PublicModule) {
        if (_module) {
            throw new Error('PublicModule must be instantiated only once!');
        }
    }
}
