import {HttpInterceptor, HttpRequest, HttpErrorResponse, HttpHandler, HttpEvent} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NzMessageService} from "ng-zorro-antd/message";
import {GENERAL_ERROR_CODE} from "../../constants/errors.constants";

export class ErrorHandlerInterceptor implements HttpInterceptor {

    constructor(private errorService: NzMessageService) {
    }

    // raportam doar erorile cu codul GB0001. Alte tipuri de erori trebuie sa le raporteze fiecare caz in parte
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const self = this;
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                },
                (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status !== 401 || !err.url || err.url.indexOf('/api/account') != 0) {
                            if (err.error) {
                                const error = err.error;
                                if (error.code === GENERAL_ERROR_CODE) {
                                    self.errorService.error(error.code + ':' + error.message, {
                                        nzDuration: 10000
                                    });
                                }
                            } else {
                            }
                        }
                    }
                }));
    }
}
