import {Injector, LOCALE_ID, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {NoopAnimationsModule} from '@angular/platform-browser/animations';
import {LocalStorageService, NgxWebstorageModule, SessionStorageService} from 'ngx-webstorage';
import {AuthInterceptor} from './shared/blocks/interceptor/auth.interceptor';
import {AuthExpiredInterceptor} from './shared/blocks/interceptor/auth-expired.interceptor';
import {ErrorHandlerInterceptor} from './shared/blocks/interceptor/errorhandler.interceptor';
import {NotificationInterceptor} from './shared/blocks/interceptor/notification.interceptor';
import {JhiMainComponent} from './app.component';
import {ConfigurationService} from './shared/service/configuration.service';
import {PublicModule} from './public/public.module';
import {PrivateModule} from './private/private.module';
import {HTTP_INTERCEPTORS, HttpClientModule, HttpClient} from '@angular/common/http';
import {appRoutes} from './app.route';
import en from '@angular/common/locales/en';
import {registerLocaleData} from '@angular/common';
import {JhiEventManager} from './shared/service/event-manager.service';
import {NzMessageService} from 'ng-zorro-antd/message';
import {en_US, ro_RO, NZ_I18N, tr_TR, ar_EG, zh_CN, cs_CZ, fr_FR, de_DE, ja_JP, ko_KR, pt_BR, ru_RU, es_ES, et_EE} from 'ng-zorro-antd/i18n';
import {HttpLoaderFactory, SharedModule, UserRouteAccessService} from './shared';
import {NzNotificationService} from "ng-zorro-antd/notification";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgxStripeModule } from 'ngx-stripe';

registerLocaleData(en);

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {anchorScrolling: 'enabled', useHash: true}),
        NgxWebstorageModule.forRoot({prefix: 'jhi', separator: '-'}),
        NoopAnimationsModule,
        BrowserModule,
        HttpClientModule,
        SharedModule,
        PublicModule,
        PrivateModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        NgxStripeModule.forRoot('pk_test_51OI9q4CIS5ziGhRZxFEd4oxTxfPlrscwiE4UfnP7OBT0vsptR8sQxjEax4zoic0HIyrWDPWlWb05oAG6nqeKWHjM00N6FvEEsr')
    ],
    declarations: [
        JhiMainComponent,
    ],
    providers: [
        ConfigurationService,
        JhiEventManager,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
            deps: [LocalStorageService, SessionStorageService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthExpiredInterceptor,
            multi: true,
            deps: [Injector]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerInterceptor,
            multi: true,
            deps: [NzMessageService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NotificationInterceptor,
            multi: true,
            deps: [Injector]
        },
        {
            provide: NZ_I18N,
            useFactory: (localeId: string) => {
                switch (localeId) {
                    case 'ar':
                        return ar_EG;
                    case 'zh':
                        return zh_CN;
                    case 'cs':
                        return cs_CZ;
                    case 'et':
                        return et_EE;
                    case 'fr':
                        return fr_FR;
                    case 'de':
                        return de_DE;
                    case 'ja':
                        return ja_JP;
                    case 'ko':
                        return ko_KR;
                    case 'pt':
                        return pt_BR;
                    case 'ro':
                        return ro_RO;
                    case 'ru':
                        return ru_RU;
                    case 'es':
                        return es_ES;
                    case 'tr':
                        return tr_TR;
                    default:
                        return en_US;
                }
            },
            deps: [LOCALE_ID]
        },
        UserRouteAccessService,
        NzNotificationService
    ],
    schemas: [NO_ERRORS_SCHEMA],
    bootstrap: [JhiMainComponent]
})
export class EthaxAppModule {
    static injector: Injector;
    static languageCode: any = {
        "ar": ar_EG,
        "zh": zh_CN,
        "cs": cs_CZ,
        "et": et_EE,
        "fr": fr_FR,
        "de": de_DE,
        "ja": ja_JP,
        "ko": ko_KR,
        "pt": pt_BR,
        "ro": ro_RO,
        "ru": ru_RU,
        "es": es_ES,
        "tr": tr_TR,
        "en": en_US,
    };
    
    

    constructor(injector: Injector) {
        EthaxAppModule.injector = injector;
    }
}
