import {Routes} from '@angular/router';
import {PublicLayoutComponent} from './components/layout/public-layout.component';

export const publicRoutes: Routes = [
    {
        path: '',
        component: PublicLayoutComponent,
        children: []
    },
];
