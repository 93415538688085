import {Directive, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';

@Directive({
    selector: '[jhiValue]',
    exportAs: 'jhiValue'
})
export class JhiValueDirective implements OnChanges, OnDestroy {

    @Input() set jhiValue(jhiValue: any) {
        this._jhiValue = jhiValue;
        this._value.next(jhiValue instanceof BehaviorSubject ? jhiValue.value : jhiValue);
    }
    @Input() context: string;

    _jhiValue: any;
    _value: Subject<boolean> = new BehaviorSubject(false);

    private externalContext;
    private subscription: Subscription;

    ngOnChanges(changes: SimpleChanges) {
        if (changes.jhiValue && this._jhiValue instanceof BehaviorSubject) {
            this.subscription = this._jhiValue.subscribe((subject) => {
                if (this.externalContext === this.context) {
                    this._value.next(subject);
                }
            });
        }
    }

    setExternalContext(externalContext: string) {
        this.externalContext = externalContext;
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
