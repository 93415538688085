import {BaseEntity, ExchangeAccountModel, ExchangeModel} from '../../index';

export class ExchangeAssetModel {
    constructor(
        public token?: string,
        public share?: number,
        public change?: number,
        public price?: number,
        public amount?: number,
        public total?: number,
    ) {
    }
}

export class CoinModel implements BaseEntity {
    constructor(
        public id?: number,
        public name?: string,
        public contractAddress?: string,
        public img?: string,
    ) {
    }
}

export class TickersModel {
    constructor(
        public tickers?: CurrencyPairCurrentPriceModel[],
        public timestamp?: string,
    ) {
    }
}

export class CurrencyPairCurrentPriceModel {
    constructor(
        public instrument?: string,
        public open?: number,
        public last?: number,
        public high?: number,
        public low?: number,
        public volume?: number,
        public timestamp?: string
    ) {
    }
}

export class CurrencyPairRowModel {
    constructor(
        public instrument?: string,
        public share?: number,
        public change?: number,
        public price?: number,
        public amount?: number,
        public total?: number
    ) {
    }
}

export class EthaxTokenModel {
    constructor(
        public updated_at?: string,
        public data?: EthaxTokenData,
    ) {
    }
}

export class EthaxTokenData {
    constructor(
        public name?: string,
        public price?: number,
        public supply?: number,
        public marketCap?: number,
    ) {
    }
}



export class ReportsExchangeAssetModel {
    constructor(
        public assets?: ExchangeAssetModel,
        public exchange?: ExchangeAccountModel,
    ) {
    }
}


