import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable, throwError} from 'rxjs';
import {TradingPairModel} from "../model/market/trading-pair.model";
import {OrderModel, OrderRequestModel} from "../model/asset/order.model";
import {ExchangeAccountModel} from "../model/exchange/exchange.model";
import {
    PositionModel,
    PositionRequestModel, RecurrentBuyDto,
    RecurrentBuyRequestModel, StopGainRuleModel, StopLossRuleModel
} from "../model/market/position.model";

@Injectable()
export class SmartTradeService {
    private exchangeResourceUrl = SERVER_API_URL + '/api/trading-pairs';
    private orderResourceUrl = SERVER_API_URL + '/api/order';
    private positionResourceUrl = SERVER_API_URL + '/api/position';
    private recurrentResourceUrl = SERVER_API_URL + '/api/recurrent';
    private adminResourceUrl = SERVER_API_URL + '/api/admin';

    constructor(private http: HttpClient) {
    }

    getTradingPairListByExchange(id: any): Observable<TradingPairModel[]> {
        return this.http.get<TradingPairModel[]>(this.exchangeResourceUrl + "/by-exchange/" + id);
    }

    getOpenOrderByTradeType(tradeType: any): Observable<TradingPairModel[]> {
        return this.http.get<TradingPairModel[]>(this.orderResourceUrl + "/list/OPEN/" + tradeType);
    }


    requestOrder(order: OrderModel): Observable<any> {
        return this.http.post<ExchangeAccountModel>(this.orderResourceUrl, order);
    }

    submitBuyNSellPosition(position: PositionRequestModel, accountId: number, userID: number): Observable<any> {
        const params = new HttpParams().set('userID', userID.toString());
        return this.http.post<PositionRequestModel>(this.positionResourceUrl + "/" + accountId + "/add", position, { params: params });
    }

    updatePositionTP(model: StopGainRuleModel, positionId: number): Observable<any> {
        return this.http.put(this.positionResourceUrl + "/" + positionId + "/updatetp", model);
    }

    updatePositionSL(model: StopLossRuleModel, positionId: number): Observable<any> {
        return this.http.put(this.positionResourceUrl + "/" + positionId + "/updatesl", model);
    }

    getAllPositionsByAccountId(accountId: number, params: any): Observable<any> {
        return this.http.get(this.positionResourceUrl + "/list/" + accountId, { params: params });
    }
    
    

    adjustPosition(orderRequest: OrderRequestModel, positionId: number) {
        return this.http.put(this.positionResourceUrl + "/" + positionId + "/adjust", orderRequest);
    }

    submitRecurringPosition(accountId: number, recurrentBuyRequestModel: RecurrentBuyRequestModel): Observable<any> {
        return this.http.post<PositionRequestModel>(this.recurrentResourceUrl + "/" + accountId + "/buy", recurrentBuyRequestModel);
    }

    getAllRecurringBuys(accountId: number): Observable<RecurrentBuyDto[]> {
        return this.http.get<RecurrentBuyDto[]>(this.recurrentResourceUrl + "/" + accountId);
    }

    cancelRecurringBuy(id: number): Observable<any> {
        return this.http.delete(this.recurrentResourceUrl + "/" + id);
    }

    getAllPosition(accountId: number, dataType: string, searchKeyword: string, page: number, size: number): Observable<PositionModel[]> {
        if (accountId == null || dataType == null || searchKeyword == null) {
            return throwError("Null value found in one of the parameters");
        }
    
        const requestUrl = this.positionResourceUrl + "/" + accountId + "/list/" + dataType + "?keyword=" + searchKeyword + "&page=" + page + "&size=" + size;
        return this.http.get<PositionModel[]>(requestUrl);
    }
    
    

    getAllPositions(dataType: string, searchKeyword: string): Observable<PositionModel[]> {
        return this.http.get<PositionModel[]>(this.adminResourceUrl + "/positions/"+dataType + "?keyword="+searchKeyword);
    }

    getAllOrders(accountId: number, dataType: string, searchKeyword : string): Observable<OrderModel[]> {
        return this.http.get<OrderModel[]>(this.orderResourceUrl + "/" + accountId + "/list/" + dataType+"?keyword="+searchKeyword);
    }

    getAllAdminOrders(dataType: string, searchKeyword : string): Observable<OrderModel[]> {
        return this.http.get<OrderModel[]>(this.adminResourceUrl + "/orders/"+dataType + "?keyword="+searchKeyword);
    }

    cancelOrder(orderId: number): Observable<any> {
        return this.http.post<ExchangeAccountModel>(this.orderResourceUrl + "/" + orderId + "/cancel", null);
    }

    closePosition(positionId: number): Observable<any> {
        return this.http.put(this.positionResourceUrl + "/" + positionId + "/close", null);
    }


    cancelPosition(positionId: number): Observable<any> {
        return this.http.put(this.positionResourceUrl + "/" + positionId + "/cancel", null);
    }
}
