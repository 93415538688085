import { Route } from '@angular/router';

import { UserRouteAccessService } from '../../../shared';
import { RegisterComponent } from './register.component';
import {RegisterSuccessComponent} from './register-success.component';

export const registerRoute: Route = {
    path: 'register',
    component: RegisterComponent,
    data: {
        authorities: [],
        pageTitle: 'register.title'
    },
    canActivate: [UserRouteAccessService]
};

export const registerSuccessRoute: Route = {
    path: 'register-success',
    component: RegisterSuccessComponent,
    data: {
        authorities: [],
        pageTitle: 'register.title'
    },
    canActivate: [UserRouteAccessService]
};

