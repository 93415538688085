import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';

@Injectable()
export class ConfigurationService {
    private resourceUrl = SERVER_API_URL + '/config';

    constructor(private http: HttpClient) {}


}
