import { NgControl } from '@angular/forms';
import {Directive, Input} from '@angular/core';

@Directive({
    selector: '[jhiDisableControl]'
})
export class JhiDisableControlDirective {

    @Input() set jhiDisableControl(condition: boolean) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
    }

    constructor(private ngControl: NgControl) {}
}
