   

  
<nz-layout class="outer-layout">
    <div nz-row>
    <div nz-col nzSpan="6" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="7" class="loginContainer p-x-2 p-x-lg-5">
        <div class="logo m-y-2">
            <h1>{{'login.ethaxTrader' | translate}}</h1>
        </div>
        <div class="header">
            <h2>{{'login.login' | translate}}</h2>
            <p>{{'login.signIn' | translate}}</p>
        </div>
        <div>
            <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="login()">
                <div nz-row>
                    <!--Email-->
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="userErrorTpl">
                                <nz-input-group nzSize="large" nzAddOnBeforeIcon="mail">
                                    <input type="text" nz-input formControlName="username" placeholder="{{'email' | translate}}" oninput="this.value = this.value.replace(/\s/g, '');" autocomplete="email"/>
                                </nz-input-group>
                                <ng-template #userErrorTpl let-control>
                                    <ng-container *ngIf="control.hasError('required')">
                                        {{'required' | translate}}
                                    </ng-container>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-row>
                    <!-- Password -->
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24"  [nzErrorTip]="passwordErrorTpl">
                                <nz-input-group [nzAddOnAfter]="passwordSuffixTemplate" nzAddOnBeforeIcon="lock" nzSize="large">
                                    <input nz-input [type]="passwordVisible ? 'text' : 'password'" [minLength]="8" oninput="this.value = this.value.replace(/\s/g, '');" autocomplete="new-password"
                                           placeholder="{{'password' | translate}}" type="password" formControlName="password" />
                                    <ng-template #passwordErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('minlength')">
                                            {{'password-size' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-input-group>
                                <ng-template #passwordSuffixTemplate>
                                    <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                                </ng-template>
                                <a class="d-block forgot-password" (click)="requestResetPassword()">{{'forgot-password' | translate}}</a>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-row>
                    <div class="m-x-auto">
                        <nz-form-item>
                            <nz-form-control >
                                <re-captcha (resolved)="resolve($event)"></re-captcha>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <nz-alert *ngIf="errorCode === 'USER_NOT_ACTIVATED' || errorCode === 'INVALID_PASSWORD'" nzType="error" [nzMessage]="'invalid-login' | translate" nzShowIcon></nz-alert>
                <nz-alert *ngIf="errorCode === 'ACCOUNT_IS_LOCKED'" nzType="error" [nzMessage]="'account-locked' | translate" nzShowIcon></nz-alert>

                <div nz-row class="m-t-4">
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <button nz-button nzBlock nzType="primary" [nzSize]="'large'" [disabled]="!validateForm.valid || captcha == undefined" (click)="onLoginSuccess()" [nzLoading]="loggingIn">{{'login-simple' | translate}}</button>
                            </nz-form-control>
                           
                            <ng-template #modalContent>
                                <form nz-form>
                                    <div nz-form-control>
                                        <label nz-form-label>{{'login.exchangeAccount' | translate}} </label>
                                        <nz-select [(ngModel)]="exchange" [ngModelOptions]="{standalone: true}" nzPlaceHolder="{{'login.chooseAccount' | translate}}" nzShowSearch>
                                            <nz-option *ngFor="let exchange of exchangeAccountList" [nzValue]="exchange.id" [nzLabel]="exchange.reference"></nz-option>
                                          </nz-select>
                                          
                                    </div>
                                    
                                    <div nz-form-item>
                                        <div nz-form-control>
                                            <label nz-form-label>{{'login.apiKey' | translate}}</label>
                                            <input nz-input type="password" placeholder="{{'login.enterApiKey' | translate}}" [(ngModel)]="apiKey" name="apiKey" />
                                        </div>
                                    </div>
                                    <div nz-form-item>
                                        <div nz-form-control>
                                            <label nz-form-label>{{'login.apiSecret' | translate}}</label>
                                            <input nz-input type="password" placeholder="{{'login.enterApiSecret' | translate}}" [(ngModel)]="apiSecret" name="apiSecret" />
                                        </div>
                                    </div>
                                    
                                </form>
                            </ng-template>
                            
                        </nz-form-item>
                    </div>
                </div>

                
            </form>
            
            <div nz-row class="m-t-2">
                <div nz-col [nzSpan]="24">
                    <nz-form-item>
                        <nz-form-control [nzSpan]="24">
                            <p class="noAccount">{{'no-account' | translate}}</p>
                            <button nz-button nzBlock nzType="default" [nzSize]="'large'" [routerLink]="['/', 'register']" >{{'login-register-button' | translate}}</button>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>

        </div>

        <img class="m-x-auto d-block" src="../../../../assets/images/astronaut.png" />
    </div>
        </div>
</nz-layout>

