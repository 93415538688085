import {Injectable} from '@angular/core';
import {AccountService} from './account.service';
import {JhiTrackerService} from '../tracker/tracker.service';
import {Observable, Subject} from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class Principal {
    private userIdentity: any;
    private authenticated = false;
    private authenticationState = new Subject<any>();

    constructor(private account: AccountService,
                private trackerService: JhiTrackerService) {}

    authenticate(identity) {
        this.userIdentity = identity;
        this.authenticated = identity !== null;
        this.authenticationState.next(this.userIdentity);
    }

    isAdmin(): boolean {
        return this.hasAnyAuthorityDirect(['ROLE_ADMINISTRATOR']);
    }

    hasAnyAuthorityDirect(authorities: string[]): boolean {
        if (!this.authenticated || !this.userIdentity || !this.userIdentity.authorities) {
            return false;
        }

        const userAuths = _.map(this.userIdentity.authorities, a => a.name);
        return _.intersection(userAuths, authorities).length > 0;
    }

    identity(force?: boolean): Promise<any> {
        if (force === true) {
            this.userIdentity = undefined;
        }

        // check and see if we have retrieved the userIdentity data from the server.
        // if we have, reuse it by immediately resolving
        if (this.userIdentity) {
            return Promise.resolve(this.userIdentity);
        }

        // retrieve the userIdentity data from the server, update the identity object, and then resolve.
        return this.account?.get()?.toPromise()?.then((response) => {
            const account = response.body;
            if (account) {
                this.userIdentity = account;
                this.authenticated = true;
                this.trackerService.connect();
            } else {
                this.userIdentity = null;
                this.authenticated = false;
            }
            this.authenticationState.next(this.userIdentity);
            return this.userIdentity;
        }).catch(() => {
            if (this.trackerService.stompClient && this.trackerService.stompClient.connected) {
                this.trackerService.disconnect();
            }
            this.userIdentity = null;
            this.authenticated = false;
            this.authenticationState.next(this.userIdentity);
            return null;
        });
    }

    getAuthenticationState(): Observable<any> {
        return this.authenticationState.asObservable();
    }

    logout() {
        return this.account.logout();
    }
}
