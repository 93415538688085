import {AfterViewInit, Component} from '@angular/core';

declare const gtag: any;

@Component({
    templateUrl: './register-success.component.html',
    styleUrls: [ './register-success.component.scss' ]
})
export class RegisterSuccessComponent implements AfterViewInit {
    ngAfterViewInit() {
        gtag('event', 'conversion', {'send_to': 'AW-932362031/k7sgCJLH3YACEK_uyrwD'});
    }
}
