import {Component, OnDestroy, OnInit} from '@angular/core';
import {DictionaryService} from './shared/service/dictionary.service';
import { AuthServerProvider } from './shared/service/auth/auth-jwt.service';
import {RoutingState} from './shared/service/routing-state.service';

@Component({
    selector: 'jhi-main',
    template: '<router-outlet></router-outlet>',
  })
  export class JhiMainComponent implements OnInit, OnDestroy {
    constructor(
      private dictionaryService: DictionaryService,
      private routingState: RoutingState,
      private authServerProvider: AuthServerProvider
    ) {}
  
    ngOnInit() {
      this.dictionaryService.init();
      this.routingState.loadRoutingHistory();
      this.authServerProvider.startSessionTimer(); // Start the session timer
    }
  
    ngOnDestroy() {
      this.routingState.unloadRoutingHistory();
      
    }
  }
  
