import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {QRCodeResponse, Validate2FACode} from "../model/2fa/google2fa.model";

@Injectable()
export class Google2faService {
    private resourceUrl = SERVER_API_URL + '/api/qrcode';

    constructor(private http: HttpClient) {}

    generateQRCode(username: string): Observable<QRCodeResponse> {
        return this.http.get<QRCodeResponse>(this.resourceUrl + "/generate/" + username);
    }

    validateFirstCode(pinCode: string, username: string): Observable<Validate2FACode> {
        return this.http.post(this.resourceUrl + `/firstcode/${username}?code=${pinCode}`, null);
    }

    validateCode(pinCode: string, username: string): Observable<Validate2FACode> {
        return this.http.post(this.resourceUrl + `/validate/${username}?code=${pinCode}`, null);
    }
}
