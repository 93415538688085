import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {CoinModel} from "../model/asset/exchange-asset.model";
import {AuditModel} from "../model/audit/audit.model";
import { map } from 'rxjs/operators';

@Injectable()
export class AuditService {
    private auditResourceUrl = SERVER_API_URL + '/api/audits';

    constructor(private http: HttpClient) {}

    getAuditList(exchangeUserId: number, startDate: number, endDate?: number, page: number = 0, size: number = 10): Observable<AuditModel[]> {
        let params = new HttpParams()
          .set('exchangeUserId', exchangeUserId.toString())
          .set('startDate', startDate.toString())
          .set('page', page.toString())
          .set('size', size.toString());
    
        if (endDate) {
          params = params.set('endDate', endDate.toString());
        }
    
        return this.http.get<{ content: AuditModel[] }>(this.auditResourceUrl, { params }).pipe(
          map(response => response.content)
        );
      }
      
     
    getPredefineEventAuditList(exchangeUserId: number): Observable<AuditModel[]> {
        return this.http.get<AuditModel[]>(this.auditResourceUrl + "/" + exchangeUserId + "/predefine-events");
    }
  

}
