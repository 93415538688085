import {Injectable} from '@angular/core';
import {HttpClient, HttpContext, HttpParams, HttpResponse} from '@angular/common/http';
import {NotificationSetting, NotificationSettings, User} from '../model/user/user.model';
import {createRequestOption2} from '../model/request-util';
import {Notification, NotificationRequest} from '../model/notification.model';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {OrderModel} from "../model/asset/order.model";
import {PositionModel} from "../model/market/position.model";
import {NGX_LOADING_BAR_IGNORED} from "@ngx-loading-bar/http-client";

@Injectable()
export class UserService {
    private resourceUrl = SERVER_API_URL + '/api/users';

    constructor(private http: HttpClient) {
    }

    updateProfile(user: User): Observable<User> {
        return this.http.put<User>(this.resourceUrl + '/profile', user);
    }

    updatePersonalData(user: User): Observable<User> {
        return this.http.put<User>(this.resourceUrl + '/personal', user);
    }

    updateMySettings(user: User): Observable<User> {
        return this.http.put<User>(this.resourceUrl + '/mysettings', user);
    }

    updateMyNotificationSettings(userID: number, notificationSettings: NotificationSetting[]): Observable<NotificationSetting[]> {
        return this.http.put<NotificationSetting[]>(this.resourceUrl + '/' + userID + '/my-notification-settings', notificationSettings);
    }

    exists(login: string): Observable<string> {
        return this.http.get(this.resourceUrl + '/exists', {
                observe: 'body',
                responseType: 'text',
                params: new HttpParams().set('email', login)
            }
        );
    }

    authorities(): Observable<string[]> {
        return this.http.get<string[]>('api/users/authorities');
    }

    getCountries(req?: any): Observable<any> {
        return this.http.get(this.resourceUrl + '/countries', {
            params: createRequestOption2(req),
            observe: 'response'
        });
    }

    updateNotificationSettings(userId: number, notificationSettings: NotificationSettings[]): Observable<any> {
        return this.http.post<any>(this.resourceUrl + '/' + userId + '/notifications/settings', notificationSettings,
            {context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true)}
        );
    }

    getNotifications(): Observable<Notification[]> {
        return this.http.get<Notification[]>(this.resourceUrl + '/notifications');
    }

    deleteUser(id: number): Observable<any> {
        return this.http.delete(this.resourceUrl + '/delete-by-id/' + id);
    }

    getNotificationSettings(userId: number): Observable<NotificationSettings[]> {
        return this.http.get<NotificationSettings[]>(this.resourceUrl + "/" + userId + '/notifications/settings');
    }

    addNotification(notifReq: NotificationRequest): Observable<any> {
        return this.http.post<any>(this.resourceUrl + '/notifications', notifReq,
            {context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),});
    }

    markAsRead(id: number): Observable<void> {
        return this.http.put<void>(this.resourceUrl + '/notifications/' + id, null,
            {context: new HttpContext().set(NGX_LOADING_BAR_IGNORED, true),});
    }

    savePassword(newPassword: string, currentPassword: string): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + '/api/account/change_password', {
            currentPassword,
            newPassword
        }, {observe: 'response'});
    }

    getAllExchangeAccountOrders(userId: number, dataType: string, searchKeyword: string): Observable<OrderModel[]> {
        return this.http.get<OrderModel[]>(this.resourceUrl + "/" + userId + "/exchanges/orders/" + dataType + "?keyword=" + searchKeyword);
    }

    getAllExchangeAccountPositions(userId: number, dataType: string, searchKeyword: any): Observable<PositionModel[]> {
        return this.http.get<PositionModel[]>(this.resourceUrl + "/" + userId + "/exchanges/positions/" + dataType + "?keyword=" + searchKeyword);
    }
}
