import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Principal} from '../../../shared';
import {LoginService} from '../../../shared';
import {TranslateService} from "@ngx-translate/core";

@Component({
    templateUrl: './public-layout.component.html',
    styleUrls: ['./public-layout.component.scss']
})
export class PublicLayoutComponent implements OnInit {
    constructor(
        protected loginService: LoginService,
        protected principal: Principal,
        public router: Router,
        public translateService: TranslateService) {}

    ngOnInit() {
        // Use the preferred language in browser
        this.translateService.use(window.navigator.language === 'ro' ? 'ro' : 'en');
    }

    login() {
        this.router.navigate(['/login'], {replaceUrl: true} );
    }

    logout() {
        this.loginService.logout();
        this.router.navigate(['']);
    }

    dashboard() {
        this.router.navigate(['/dashboard']);
    }
}
