import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable, throwError} from 'rxjs';
import { BackTestModel, StrategyAutomationBacktestDto, StrategyAutomationModel, StrategyAutomationStatistics } from '../model/strategy-execution/strategy-execution.model';
import { catchError } from 'rxjs/operators';

@Injectable()
export class StrategyAutomationService {
    private strategyAutomationResourceUrl = SERVER_API_URL + '/api/strategy-automations/';
 
    constructor(private http: HttpClient) {}
    

    listStrategyAutomation(accountId: any,params: any): Observable<any> {
        return this.http.get<any>(this.strategyAutomationResourceUrl+'find-all/'+accountId,{ params: params });
    }
    
    getStrategyAutomationById(id: any): Observable<any[]> {
        return this.http.get<any[]>(this.strategyAutomationResourceUrl+'find-by-id/'+id);
    }

    createStrategyAutomation(accountId: any,strategy: any): Observable<any> {
        return this.http.post<any>(this.strategyAutomationResourceUrl+accountId , strategy);
    }

    getStrategyAutomationsBacktest(strategyAutomationId: any, params: any): Observable<StrategyAutomationBacktestDto[]> {
        return this.http.get<StrategyAutomationBacktestDto[]>(this.strategyAutomationResourceUrl + strategyAutomationId + '/backtests', { params: params });
    }

    backtestStrategyAutomation(strategyAutomationId: any, backTestModel: BackTestModel): Observable<StrategyAutomationBacktestDto> {
        return this.http.put<StrategyAutomationBacktestDto>(this.strategyAutomationResourceUrl + strategyAutomationId + '/backtest', backTestModel);
      }

      findStrategyAutomationBacktestById(backtestId: any): Observable<StrategyAutomationBacktestDto> {
        return this.http.get<StrategyAutomationBacktestDto>(this.strategyAutomationResourceUrl + 'backtest/' + backtestId);
    }

    getOverallAutomationStatistics(strategyAutomationId: any): Observable<StrategyAutomationStatistics> {
      return this.http.get<StrategyAutomationStatistics>(this.strategyAutomationResourceUrl  + strategyAutomationId + '/statistics')
          .pipe(
              catchError(this.handleError)
          );
  }  
    
    private handleError(error: HttpErrorResponse) {
      if (error.status === 404) {
        // handle not found error
        console.error('No statistics found for this strategy automation ID');
      } else {
        // handle other types of error
        console.error('An error occurred:', error);
      }
      return throwError(
        'Something bad happened; please try again later.');
    }

    stopStrategyAutomation(strategyAutomationId: any): Observable<any> {
        return this.http.put(this.strategyAutomationResourceUrl +'stop/'+strategyAutomationId,null);
    }

    startStrategyAutomation(strategyAutomationId: any): Observable<any> {
        return this.http.put(this.strategyAutomationResourceUrl  + 'start/'+strategyAutomationId,null);
    }

    getStrategyExecutionByAutomation(strategyAutomationId: any): Observable<any[]> {
        return this.http.get<any[]>(this.strategyAutomationResourceUrl  +strategyAutomationId+'/active-strategy-instances');
    }

    getAllStrategyExecutionByAutomation(strategyAutomationId: any, params: any): Observable<any[]> {
        return this.http.get<any[]>(this.strategyAutomationResourceUrl  +strategyAutomationId+'/strategy-instances',  { params: params });
    }

}