<nz-layout class="outer-layout">
    <div nz-row>
        <div nz-col nzSpan="6" nzXs="24" nzSm="24" nzMd="12" nzLg="12" nzXl="12" nzXXl="7" class="registerContainer p-x-2 p-x-lg-5">
            <div class="logo m-y-2">
                <h1>ETHAX TRADER</h1>
            </div>
            <div class="header">
                <h2>Create a new account</h2>
                <p>sign up an account</p>
            </div>
            <div>
                <!-- <div nz-col class="m-b-2">
                    <div nz-row nzJustify="center" nzSpan="4">
                        <p style="font-size: 5vh;margin-top: 5vh;">Waiting List</p>
                    </div>
                    <div nz-row nzJustify="center" nzSpan="4">
                        <span style="width: 40vw;text-align: center;">Due to soaring demand for our crypto payment solution, we've temporarily paused the new exchange account's connections. Join the waitlist and enter the queue to be notified when we're ready to accept new registrations.</span>
                    </div>
                </div>
                <form nz-form [formGroup]="contactForm" class="login-form"  (ngSubmit)="submitContact(contactForm.value)" autocomplete="off">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="nameErrorTpl">
                                    <nz-input-group nzSize="large" nzAddOnBeforeIcon="user">
                                        <input nz-input formControlName="name" placeholder="{{'name' | translate}}" />
                                    </nz-input-group>
                                    <ng-template #nameErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('unwantedCharacters')">
                                            {{'Only letters, numbers, and special characters are allowed.' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        
                    </div>
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="surnameErrorTpl">
                                    <nz-input-group nzSize="large" nzAddOnBeforeIcon="user">
                                        <input nz-input formControlName="surname" placeholder="{{'surname' | translate}}" />
                                    </nz-input-group>
                                    <ng-template #surnameErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('unwantedCharacters')">
                                            {{'Only letters, numbers, and special characters are allowed.' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        
                    </div>

                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="emailErrorTpl">
                                    <nz-input-group nzSize="large" nzAddOnBeforeIcon="mail">
                                        <input nz-input formControlName="email" placeholder="{{'email' | translate}}" />
                                    </nz-input-group>
                                    <ng-template #emailErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('email')">
                                            {{'invalid-email' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('anon')">
                                            {{'anon-email' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="contactNumberErrorTpl">
                                    <nz-input-group nzSize="large" nzAddOnBeforeIcon="phone">
                                        <input nz-input type="tel" formControlName="contactNumber"
                                        maxlength="20" minlength="8" placeholder="{{'phone-ph' | translate}}" />
                                    </nz-input-group>
                                    <ng-template #contactNumberErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('email')">
                                            {{'invalid-email' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('anon')">
                                            {{'anon-email' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>


                    
                    <nz-form-item nz-row>
                        <nz-form-control [nzSpan]="24">
                            <button nz-button nzBlock nzType="primary" [disabled]="!contactForm.valid " [nzLoading]="isSavingContactForm">{{'submit' | translate}}</button>
                        </nz-form-control>
                    </nz-form-item>


                </form> -->
                <form nz-form [formGroup]="validateForm" class="login-form"  (ngSubmit)="register(validateForm.value)" autocomplete="off">
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="fullNameErrorTpl">
                                    <nz-input-group nzSize="large" nzAddOnBeforeIcon="user">
                                        <input nz-input formControlName="fullName" placeholder="{{'full-name' | translate}}" />
                                    </nz-input-group>
                                    <ng-template #fullNameErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('minlength')">
                                            {{'name-size' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('unwantedCharacters')">
                                            {{'Only letters, numbers, and special characters are allowed.' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                        
                    </div>

                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" nzHasFeedback [nzErrorTip]="emailErrorTpl">
                                    <nz-input-group nzSize="large" nzAddOnBeforeIcon="mail">
                                        <input nz-input formControlName="email" placeholder="{{'email' | translate}}" />
                                    </nz-input-group>
                                    <ng-template #emailErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('email')">
                                            {{'invalid-email' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('anon')">
                                            {{'anon-email' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzSpan]="24" [nzErrorTip]="passwordErrorTpl">
                                    <nz-input-group [nzAddOnAfter]="passwordSuffixTemplate" nzAddOnBeforeIcon="lock" nzSize="large">
                                        <input nz-input [type]="passwordVisible ? 'text' : 'password'" autocomplete="new-password"
                                               placeholder="{{'password' | translate}}" type="password" [minlength]="8" formControlName="password" />
                                    </nz-input-group>
                                    <ng-template #passwordSuffixTemplate>
                                        <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                                    </ng-template>
                                    <ng-template #passwordErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'required' | translate}}
                                        </ng-container>
                                        <ng-container *ngIf="control.hasError('minlength')">
                                            {{'password-size' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>

                    <div nz-row>
                        <div class="m-x-auto">
                            <nz-form-item>
                                <nz-form-control>
                                    <re-captcha (resolved)="resolve($event)"></re-captcha>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    <div nz-row>
                        <div nz-col [nzSpan]="24">
                            <nz-form-item>
                                <nz-form-control [nzErrorTip]="termsErrorTpl">
                                    <label nz-checkbox formControlName="agreeTerms">
                                        {{'agree-terms' | translate}} <a href="javascript:void(0);" (click)="openTermsPDF()">{{'terms' | translate}}</a>
                                    </label>
                                    <ng-template #termsErrorTpl let-control>
                                        <ng-container *ngIf="control.hasError('required')">
                                            {{'terms-required' | translate}}
                                        </ng-container>
                                    </ng-template>
                                </nz-form-control>
                            </nz-form-item>
                        </div>
                    </div>
                    
                    <nz-form-item nz-row>
                        <nz-form-control [nzSpan]="24">
                            <button nz-button nzBlock nzType="primary" [disabled]="!validateForm.valid || captcha == undefined" [nzLoading]="isSaving">{{'create-account' | translate}}</button>
                        </nz-form-control>
                    </nz-form-item>


                </form>
                <div nz-row class="m-t-4">
                    <div nz-col [nzSpan]="24">
                        <nz-form-item>
                            <nz-form-control [nzSpan]="24">
                                <p class="noAccount">{{'register-login-button' | translate}}</p>
                                <button nz-button nzBlock nzType="default" [nzSize]="'large'" [routerLink]="['/', 'login']" >{{'login-here' | translate}}</button>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

            </div>
            <img class="m-x-auto d-block" src="../../../../assets/images/astronaut.png" />
        </div>
    </div>
</nz-layout>