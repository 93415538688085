import {Injectable} from '@angular/core';
import {Principal} from './auth/principal.service';
import {AuthServerProvider} from './auth/auth-jwt.service';
import {JhiTrackerService} from './tracker/tracker.service';
import {StateStorageService} from './auth/state-storage.service';

@Injectable()
export class LoginService {
  
    constructor(
        private principal: Principal,
        private trackerService: JhiTrackerService,
        private authServerProvider: AuthServerProvider,
        private stateStorageService: StateStorageService
    ) {}

    login(credentials, callback?) {
        const cb = callback || function() {};
       
        return new Promise((resolve, reject) => {
            this.authServerProvider.login(credentials).subscribe((data) => {
                this.principal.identity(true).then((account) => {
                    this.trackerService.sendActivity();
                    this.stateStorageService.storeCurrentAccount(account);
                    localStorage.setItem('is_as_guided', 'false');
                    resolve(data);
                });
                return cb();
            }, (err) => {
                reject(err);
                return cb();
            });
        });
    }

    loginWithToken(jwt, rememberMe) {
        return this.authServerProvider.loginWithToken(jwt, rememberMe);
    }

    logout() {
        this.principal.logout().toPromise().then((response) => {
            this.authServerProvider.logout().subscribe();
            this.principal.authenticate(null);
        });
    }
}
