<div nz-row nzJustify="center" nzAlign="middle" class="m-t-5 success" *ngIf="success">
    <div nz-col nzSpan="12">
        <nz-result
                nzStatus="success"
                [nzTitle]="'activation-success-title' | translate"
                [nzSubTitle]="'activation-success-subtitle' | translate">
            <div nz-result-extra>
                <button nz-button nzType="primary" class="login-button" [routerLink]="['/', 'login']">{{'back-to-login' | translate}}</button>
            </div>
        </nz-result>
    </div>
</div>

<div nz-row nzJustify="center" nzAlign="middle" class="m-t-5 error" *ngIf="error">
    <div nz-col nzSpan="12">
        <nz-result
                nzStatus="error"
                [nzTitle]="'activation-error-title' | translate"
                [nzSubTitle]="error">
            <div nz-result-extra>
                <button nz-button nzType="primary" class="login-button" [routerLink]="['/', 'login']">{{'back-to-login' | translate}}</button>
            </div>
        </nz-result>
    </div>
</div>
