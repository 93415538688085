import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {ActivateService} from './activate.service';
import {ErrorUtil} from '../../../shared/util/error.util';
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
    selector: 'jhi-activate',
    templateUrl: './activate.component.html',
    styleUrls: [ './activate.component.scss' ]
})
export class ActivateComponent implements OnInit {
    error: string;
    success: string;

    constructor(
        private activateService: ActivateService,
        private errorSerivce: NzMessageService,
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.activateService.get(params['key']).subscribe(() => {
                this.error = null;
                this.success = 'OK';
            }, (response) => {
                this.success = null;
                if (response.error) {
                    this.error = response.error.message;
                } else {
                    ErrorUtil.handleError(this.errorSerivce, response);
                }
            });
        });
    }

    login() {
        this.router.navigate(['/login'], {replaceUrl: true} );
    }
}
