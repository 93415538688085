export const DEFAULT_GLOBAL_ERROR_CODE = 'GB0000';
export const GENERAL_ERROR_CODE = 'GB0001';
export const NOT_FOUND_CODE = 'GB0002';

export const USER_COMMON_ERROR_CODE = "UR0001";
export const USER_NOT_FOUND_ERROR_CODE = "UR0002";
export const DUPLICATED_USERNAME_CODE = "UR0003";
export const INVALID_PASSWORD_CODE = "UR0004";
export const EMAIL_REGISTERED_CODE = "UR0005";

export const USER_EXCHANGE_COMMON_ERROR = "UER0001";
export const USER_EXCHANGE_ID_NULL = "UER0002";
export const INVALID_API_KEY = "UER0004";
export const INVALID_API_SECRET = "UER0005";

