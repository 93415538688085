import { Routes } from '@angular/router';
import { PrivateLayoutComponent } from './components/layout/private-layout.component';
import { Authority } from '../shared';

export const privateRoutes: Routes = [
    {
        path: '',
        component: PrivateLayoutComponent,
        children: [
            {
                path: 'user',
                loadChildren: () => import('./user/user.module').then(m => m.UserModule),
                data: {
                    authorities: [Authority.EXTERNAL_TRADER],
                }
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                data: {
                    authorities: [Authority.EXTERNAL_TRADER],
                }
            },
            {
                path: 'exchange',
                loadChildren: () => import('./exchange/exchange.module').then(m => m.ExchangeModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'wise-trades',
                loadChildren: () => import('./smart-trades/smart-trades.module').then(m => m.SmartTradesModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'automated-trading',
                loadChildren: () => import('./automated-trading/automated-trading.module').then(m => m.AutomatedTradingModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'admin',
                loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'sentiments',
                loadChildren: () => import('./sentiment/sentiment.module').then(m => m.SentimentModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'market-place',
                loadChildren: () => import('./market_place/market_place.module').then(m => m.MarketPlaceModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'crypto-news',
                loadChildren: () => import('./crypty-news/crypto-news.module').then(m => m.CryptoNewsModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports-logs/reports-logs.module').then(m => m.ReportsLogsModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER],
                }
            },
            {
                path: 'subscription',
                loadChildren: () => import('./subscription/subscription.module').then(m => m.SubscriptionModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER, Authority.EXTERNAL_TRADER],
                }
            },
            {
                path: 'payment-checkout',
                loadChildren: () => import('./payment-checkout/payment-checkout.module').then(m => m.PaymentCheckoutModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER, Authority.EXTERNAL_TRADER],
                }
            },
            {
                path: 'payment-success',
                loadChildren: () => import('./payment-success/payment-success.module').then(m => m.PaymentSuccessModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER, Authority.EXTERNAL_TRADER],
                }
            },
            {
                path: 'payment-failure',
                loadChildren: () => import('./payment-failure/payment-failure.module').then(m => m.PaymentFailureModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER, Authority.EXTERNAL_TRADER],
                }
            },
            {
                path: 'customer-support',
                loadChildren: () => import('./customer-support/customer-support.module').then(m => m.CustomerSupportModule),
                data: {
                    authorities: [Authority.INTERNAL_TRADER, Authority.EXTERNAL_TRADER],
                }
            }
        ]
    }
];
