import {Observable, Observer, Subscription} from 'rxjs';

export class WebsocketClient {
    clientObservable: Observable<any>;
    clientObserver: Observer<any>;
    subscription: Subscription;
    topic: string;

    constructor() {
        this.clientObservable = new Observable((observer) => {
            this.clientObserver = observer;
        });
    }

    public subscribe(callback: any) {
        return this.clientObservable.subscribe(callback);
    }

    public unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        if (this.clientObserver) {
            this.clientObserver.complete();
        }
    }
}
