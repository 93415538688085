import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {SERVER_API_URL} from '../../../shared/constants/app.constants';
import {Observable} from 'rxjs';
import {ContactFormModel} from '../../../shared/model/contact-form.model'

@Injectable()
export class Register {

    constructor(private http: HttpClient) {}

    save(account: any): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + '/api/register', account, {
          observe: 'response',
          responseType: 'json'
        });
      }
    contactForm(contactForm: ContactFormModel): Observable<HttpResponse<any>> {
        return this.http.post(SERVER_API_URL + '/api/contactUs', contactForm, {
          observe: 'response',
          responseType: 'json'
        });
      }
      
}
