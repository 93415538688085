export class User {
    public id?: any;
    public login?: string;
    public password?: string;
    public fullName?: string;
    public phoneNumber?: string;
    public phoneNumberPrefix?: string;
    public postalAddress?: string;
    public notificationMode?: NotificationMode[];
    public notificationSettings?: NotificationSettings[];
    public authorities?: AuthorityModel[];
    public currentLanguage?: string;
    public enable2FA?: boolean;
    public enablePaperTrading?: boolean;
    public loginIPCheck?: boolean;
    public registerIpAddress?:string;
    public createdDate?: Date; // Uncommented this line
    public locked?: boolean;
    public isCleared?: boolean;
}
export class UpdateUserModel {
    public id?: any;
    public fullName?: string;
    public currentLanguage?: string;
    public phoneNumberPrefix?: string;
    public phoneNumber?: string;
    public postalAddress?: string;
    public city?: string;
    public state?: string;
    public country?: string;
    public zipCode?: string;
    public gender?: string;
}
export class AuthorityModel {
    public name?: string;
}

export class UserRequestModel {
    public id?: any;
    public fullName?: string;
    public authorities?: string;
}

export class NotificationSetting {
    public notificationMode?: any;
    public isActiveForEmail?: boolean;
    public isActiveForWebsite?: boolean;
    public precedence?: number;
}

export enum NotificationMode {
    ACCOUNT = 'ACCOUNT' as any,
    NEWS = 'NEWS' as any,
    MAINTENANCE = 'MAINTENANCE' as any,
    OPEN_POSITION = 'OPEN_POSITION' as any,
    CLOSE_POSITION = 'CLOSE_POSITION' as any,
    PLACE_ORDER = 'PLACE_ORDER' as any,
    EXECUTE_ORDER = 'EXECUTE_ORDER' as any,
    CANCEL_ORDER = 'CANCEL_ORDER' as any,
    START_STRATEGY = 'START_STRATEGY' as any,
    STOP_STRATEGY = 'STOP_STRATEGY' as any,

}

export class NotificationSettings{
    public notificationMode?: NotificationMode;
    public isActive? : boolean;
}

export enum Authority {
    EXTERNAL_TRADER = 'ROLE_EXTERNAL_TRADER' as any,
    INTERNAL_TRADER = 'ROLE_INTERNAL_TRADER' as any,
    ADMINISTRATOR = 'ROLE_ADMINISTRATOR' as any,
}
