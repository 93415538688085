import {Component, Input, OnInit} from '@angular/core';
import {SocialService} from './social.service';
import {CSRFService} from '../../service/auth/csrf.service';
import {Principal} from '../../service/auth/principal.service';
import {AuthServerProvider} from '../../service/auth/auth-jwt.service';
import {ConsoleCookieService} from '../../service/console.cookie.service';

@Component({
    selector: 'jhi-social',
    templateUrl: './social.component.html',
    styleUrls: [
        'social.scss'
    ]
})
export class JhiSocialComponent implements OnInit {
    @Input() provider: string;
    @Input() mapp: string;
    @Input() preLabel: string;
    @Input() existingAccount: boolean;
    @Input() dashboardId: number;
    @Input() campaignId: number;

    label: string;
    providerSetting: string;
    providerURL: string;
    csrf: string;

    constructor(
        public principal: Principal,
        private csrfService: CSRFService,
        private socialService: SocialService,
        private authService: AuthServerProvider,
    ) {}

    ngOnInit() {
        this.label = this.provider.charAt(0).toUpperCase() + this.provider.slice(1);
        this.providerSetting = this.socialService.getProviderSetting(this.provider);
        this.providerURL = this.socialService.getProviderURL(this.provider);
        this.csrf = this.csrfService.getCSRF();
    }

    onSubmit() {
        if (this.mapp) {
            this.principal.identity().then((account) => {
                ConsoleCookieService.setCookie('social-connect', btoa(`${account.login},${this.dashboardId},${this.campaignId}`), 1);
            });
        }
    }

    token(): string {
        return this.authService.getToken();
    }
}
