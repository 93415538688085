import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {TranslateService} from "@ngx-translate/core";

@Pipe({ name: 'time_ago' })
export class TimeAgoPipe implements PipeTransform {

    private translateService: TranslateService;

    constructor(translateService: TranslateService) {
        this.translateService = translateService;
    }

    transform(value: Date) {
        if (undefined === value) {
            return '';
        }

        const date = moment(value).locale(this.translateService.currentLang);
        return (date && date.fromNow) ? date.fromNow() : "";
    }
}
