import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {OTPCodeResponse} from "../model/sms2fa/otpcode.model";

@Injectable()
export class SMSOtpService {
    private resourceUrl = SERVER_API_URL + '/api/sms-code';

    constructor(private http: HttpClient) {}

    generateOTPCode(): Observable<OTPCodeResponse> {
        return this.http.get<OTPCodeResponse>(this.resourceUrl + "/generate/");
    }

}
