import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {Indicators, Strategy, StrategyModel} from "../model/strategy/strategy.model";
import {ExchangeAccountModelDTO} from "../model/exchange/exchange.model";
import {CreateGridBot, GridBotExecutionDto, GridBotModel} from "../model/grid-bot/grid-bot.model";
import {OrderModel} from "../model/asset/order.model";

@Injectable()
export class GridBotService {
    private gridBotResourceUrl = SERVER_API_URL + '/api/grid-bots/';
    private gridBotExecutionResourceUrl = SERVER_API_URL + '/api/grid-bot-executions/';

    constructor(private http: HttpClient) {}

    findGridBot(id: number): Observable<GridBotModel> {
        return this.http.get<GridBotModel>(this.gridBotResourceUrl + id);
    }

    getGridBotsData(accountId: number, type: string): Observable<GridBotModel[]> {
        return this.http.get<GridBotModel[]>(this.gridBotResourceUrl + 'account/' + type +'/' + accountId);
    }

    createGridBot(gridBot: CreateGridBot, accountId: number, userID: number): Observable<any> {
        const params = new HttpParams().set('userID', userID.toString());
        return this.http.post<CreateGridBot>(this.gridBotResourceUrl + 'account/' + accountId, gridBot, { params: params });
    }
        
    

    updateGridBot(gridBot: CreateGridBot, id: number): Observable<any> {
        return this.http.put<CreateGridBot>(this.gridBotResourceUrl + id, gridBot);
    }

    startGridBot(id: number): Observable<any> {
        return this.http.put<CreateGridBot>(this.gridBotResourceUrl + id + '/start', null);
    }

    disableGridBot(id: number): Observable<any> {
        return this.http.delete<CreateGridBot>(this.gridBotResourceUrl + id);
    }

    stopGridBot(id: number): Observable<any> {
        return this.http.put<CreateGridBot>(this.gridBotExecutionResourceUrl + id + '/stop', null);
    }

    getGridBotExecutionData(gridBotId: number, isActive: boolean): Observable<GridBotExecutionDto[]> {
        return this.http.get<GridBotExecutionDto[]>(this.gridBotExecutionResourceUrl + 'grid-bot/' + gridBotId + "?isActive=" + isActive);
    }

    getGridBotExecutionOrdersData(gridBotExecutionId: number): Observable<OrderModel[]> {
        return this.http.get<OrderModel[]>(this.gridBotExecutionResourceUrl + gridBotExecutionId + "/orders");
    }

}
