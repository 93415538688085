import {Component, ElementRef, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {PasswordResetFinishService} from './password-reset-finish.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NzMessageService} from "ng-zorro-antd/message";
import {ErrorUtil} from '../../../../shared/util/error.util';

@Component({
    selector: 'jhi-password-reset-finish',
    templateUrl: './password-reset-finish.component.html',
    styleUrls: [ './password-reset-finish.component.scss' ]
})
export class PasswordResetFinishComponent implements OnInit {
    keyMissing: boolean;
    key: string;
    validateForm: UntypedFormGroup;
    passwordVisible = false;
    confirmPasswordVisible = false;

    constructor(
        private passwordResetFinishService: PasswordResetFinishService,
        private route: ActivatedRoute,
        private elementRef: ElementRef,
        private router: Router,
        private errorService: NzMessageService,
        private fb: UntypedFormBuilder,
    ) {
        this.validateForm = this.fb.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            checkPassword: ['', [Validators.required, this.confirmPasswordValidator]],
        });
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.key = params['key'];
        });
        this.keyMissing = !this.key;
    }

    validateConfirmPassword(): void {
        setTimeout(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
    }

    confirmPasswordValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return {error: true, required: true};
        } else if (control.value !== this.validateForm.controls.password.value) {
            return {confirm: true, error: true};
        }
        return {};
    };

    finishReset(value: any) {
        this.passwordResetFinishService.save({key: this.key, newPassword: value.password}).subscribe(
            () => {
                this.router.navigate(['/reset/finish-success']);
            },
            (error) => {
                ErrorUtil.handleError(this.errorService, error)
            });
    }
}
