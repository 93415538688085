import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import {ExchangeModel, ExchangeAccountModel, ExchangeAccountModelDTO} from '../model/exchange/exchange.model';
import {CurrencyPairCurrentPriceModel, TickersModel} from "../model/asset/exchange-asset.model";
import {map} from "rxjs/operators";

@Injectable()
export class DownloadService {
    private downloadResourceUrl = SERVER_API_URL + '/api/downloads';

    constructor(private http: HttpClient) {}

    fetchOutput(): Observable<ArrayBuffer> {
        let headers = new HttpHeaders();

        const options: {
            headers?: HttpHeaders;
            observe?: 'body';
            params?: HttpParams;
            reportProgress?: boolean;
            responseType: 'arraybuffer';
            withCredentials?: boolean;
        } = {
            responseType: 'arraybuffer'
        };

        return this.http.get(this.downloadResourceUrl + "/assets/", options).pipe(
                map((file: ArrayBuffer) => {
                    return file;
                })
            );
    }
}
