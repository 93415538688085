import { FocusMonitor } from '@angular/cdk/a11y';
import {
    forwardRef,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation, TemplateRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {InputBoolean} from "ng-zorro-antd/core/util";

export interface StyledCheckBoxOptionInterface {
    label: string;
    value: string;
    checked?: boolean;
    disabled?: boolean;
    span?: string;
}

@Component({
    selector: 'styled-checkbox-group',
    exportAs: 'styledCheckboxGroup',
    preserveWhitespaces: false,
    encapsulation: ViewEncapsulation.None,
    templateUrl: './styled-checkbox-group.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => StyledCheckboxGroupComponent),
            multi: true
        }
    ]
})
export class StyledCheckboxGroupComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() nzLabel: TemplateRef<void>;

    // tslint:disable-next-line:no-any
    onChange: (value: any) => void = () => null;
    // tslint:disable-next-line:no-any
    onTouched: () => any = () => null;
    options: StyledCheckBoxOptionInterface[] = [];
    @Input() @InputBoolean() nzDisabled = false;

    onOptionChange(): void {
        this.onChange(this.options);
    }

    trackByOption(_index: number, option: StyledCheckBoxOptionInterface): string {
        return option.value;
    }

    constructor(
        private elementRef: ElementRef,
        private focusMonitor: FocusMonitor,
        private cdr: ChangeDetectorRef,
        renderer: Renderer2
    ) {
        renderer.addClass(elementRef.nativeElement, 'ant-checkbox-group');
    }

    ngOnInit(): void {
        this.focusMonitor.monitor(this.elementRef, true).subscribe(focusOrigin => {
            if (!focusOrigin) {
                Promise.resolve().then(() => this.onTouched());
            }
        });
    }

    ngOnDestroy(): void {
        this.focusMonitor.stopMonitoring(this.elementRef);
    }

    writeValue(value: StyledCheckBoxOptionInterface[]): void {
        this.options = value;
        this.cdr.markForCheck();
    }

    registerOnChange(fn: (_: StyledCheckBoxOptionInterface[]) => {}): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.nzDisabled = isDisabled;
        this.cdr.markForCheck();
    }
}
