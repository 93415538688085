import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import * as AllIcons from '@ant-design/icons-angular/icons';
import {IconDefinition} from '@ant-design/icons-angular';
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import {NZ_ICONS} from "ng-zorro-antd/icon";
import {TranslateModule} from "@ngx-translate/core";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";

const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule,
        LoadingBarHttpClientModule
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        LoadingBarHttpClientModule,
        
    ],
    providers: [
        {provide: NZ_ICONS, useValue: icons}
    ]
})
export class SharedLibsModule {
}
