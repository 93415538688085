import {Router} from '@angular/router';
import {Injectable, Optional, SkipSelf} from '@angular/core';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';
import {StateStorageService} from "./auth/state-storage.service";

@Injectable()
export class RoutingState {
    private history = [];
    subscription: Subscription;

    constructor(@Optional() @SkipSelf() routingState: RoutingState,
                private router: Router,
                private stateStorageService: StateStorageService) {
        if (routingState) {
            throw new Error('Routing state service can be instantiated only once!');
        }
    }

    public getLastUrl() {
        let history = this.stateStorageService.getNavHistory();
        if (!history || history.length === 0) {
            return null;
        }
        history = _.filter(history,
                url => url !== '/login' && url !== '/accessdenied' && url !== '/register' && url !== '/error' && url !== '/'
                    && url !== '/activate' && url !== '/logout' && url !== '/reset/finish' && url !== '/reset/request'
                    && url !== '/reset/request-success' && url !== '/reset/finish-success' && url !== '/register-success'
                    && url !== '/social-auth' && url !== '/social-register/google'
                    && !url.startsWith('/reset/finish') && !url.startsWith('/activate'));
        if (history.length === 0) {
            return null;
        }
        return history[history.length - 1];
    }

    public loadRoutingHistory(): void {
        this.subscription = this.router.events.subscribe((event: any) => {
            const url = event.url;
            if (url && this.history.indexOf(url) < 0) {
                const persisted = this.stateStorageService.getNavHistory();
                if (persisted && persisted.length <= 5) {
                    this.history = [...persisted, url];
                } else {
                    this.history = [url];
                }
                this.stateStorageService.storeNavHistory(this.history);
            }
        });
    }

    public unloadRoutingHistory(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public clearHistory() {
        this.history = [];
    }
}
