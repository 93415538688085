import { Route } from '@angular/router';

import { UserRouteAccessService } from '../../../../shared';
import { PasswordResetFinishComponent } from './password-reset-finish.component';
import {PasswordResetFinishSuccessComponent} from './password-reset-finish-success.component';

export const passwordResetFinishRoute: Route = {
    path: 'reset/finish',
    component: PasswordResetFinishComponent,
    data: {
        authorities: [],
        pageTitle: 'global.menu.account.password'
    },
    canActivate: [UserRouteAccessService]
};

export const passwordResetFinishSuccessRoute: Route = {
    path: 'reset/finish-success',
    component: PasswordResetFinishSuccessComponent,
    data: {
        authorities: [],
        pageTitle: 'global.menu.account.password'
    },
    canActivate: [UserRouteAccessService]
};
