import {BaseEntity, CurrencyPairCurrentPriceModel, User} from '../../index';

export class ExchangeModel implements BaseEntity {
    constructor(
        public id?: number,
        public exchangeName?: string,
        public description?: string,
        public exchangeExtraParameters?: ExchangeExtraParameters,
        public tickerCurrencyPairs?: CurrencyPairs,
        public sandboxTickerCurrencyPairs?: CurrencyPairs,
        public exchangeMetaData?: ExchangeMetaData,
        public isPaperTrade?: boolean,
        public reference?: string,
        public topicName?: string,
        public isSandbox?: boolean,
    ) {
    }
}

export class ExchangeExtraParameters  {
    constructor(
        public extraParams?: Map<String,ExchangeExtraParameter>,
    ) {
    }
}



export class ExchangeMetaData  {
    constructor(
        public currency_pairs?: Map<String,MetaDataCurrencyPairModel>,
        public currencies?: Map<String,MetaDataCurrencyModel>,
    ) {
    }
}

export class MetaDataCurrencyPairModel  {
    constructor(
        public tradingFee?: number,
        public minimumAmount?: number,
        public maximumAmount?: number,
        public counterMinimumAmount?: number,
        public counterMaximumAmount?: number,
        public baseScale?: number,
        public priceScale?: number,
        public amountStepSize?: string,
        public marketOrderEnabled?: boolean,
    ) {
    }
}

export class MetaDataCurrencyModel  {
    constructor(
        public scale?: number,
        public withdrawal_fee?: number,
    ) {
    }
}

export class ExchangeExtraParameter  {
    constructor(
        public paramValue?: string,
        public required?: boolean,
    ) {
    }
}

export class CurrencyPairs  {
    constructor(
        public currencyPairs?: string[],
    ) {
    }
}

export class CurrencyPairsModel  {
    constructor(
        public currencyPairMap?: Map<String,CurrencyModel>,
    ) {
    }
}

export class CurrencyModel  {
    constructor(
        public base_scale?: number,
        public counter_max_amount?: number,
        public counter_min_amount?: number,
        public marketOrderEnabled?: boolean,
        public max_amount?: number,
        public min_amount?: number,
        public price_scale?: number,
        public tradingFeeCurrency?: string,
        public trading_fee?: number,
    ) {
    }
}

export class ExchangeAccountModel implements BaseEntity {
    constructor(
        public id?: number,
        public exchange?: ExchangeModel,
        public reference?: string,
        public user?: User,
        public currencyPairs?: CurrencyPairsModel,
        public tradingWallet?:TradingWalletModel,
        public totalBalance?: number,
        public isConnected?: boolean,
        public totalAssetValue?: number
    ) {
    }
}

export class ExchangeAccountModelDTO {
    constructor(
        public exchangeId?: number,
        public reference?: string,
        public apiKey?: string,
        public secretKey?: string,
        public exchangeExtraParameters?: ExchangeAccountExtraParameters, // Add this line
    ) {
    }
}



export class ExchangeAccountExtraParameters {
    constructor(
        public extraParams?: {
            [key: string]: any;
        },
    ) {
    }
}


export class ExchangeAccountExtraParams  {
    constructor(
        public passphrase?: String,
        public Use_Sandbox?: boolean,
    ) {
    }
}

export class TradingWalletModel  {
    constructor(
        public balances?: Map<String,WalletModel>,
    ) {
    }
}

export class WalletModel  {
    constructor(
        public currency?: String,
        public total?: number,
        public available?: number,
        public currentPrice?: number,
        public low?: number,
        public high?: number,
        public percentage?: number,
    ) {
    }
}

export class WalletHistoryDto  {
    constructor(
        public bucket?: any,
        public tradingWallet?: WalletDto,
    ) {
    }
}

export class WalletHistoryAssets  {
    constructor(
        public bucket?: any,
        public totalUSD?: number,
        public totalBTC?: number,
    ) {
    }
}

export class WalletDto implements BaseEntity {
    constructor(
        public id?: number,
        public balances?: Map<CurrencyDto, BalanceDto>,
        public name?: string,
        public features?: string[],
    ) {
    }
}

export class CurrencyDto  {
    constructor(
        public code?: String,
    ) {
    }
}

export class BalanceDto {
    constructor(
        public currency?: CurrencyDto,
        public total?: number,
        public available?: number,
        public frozen?: number,
        public loaned?: number,
        public borrowed?: number,
        public withdrawing?: number,
        public depositing?: number,
        public timestamp?: string,
    ) {
    }
}

export class AccountStatisticsDto {
    constructor(
        public overallGainMap : Map<String, number>,
        public thirtyDaysGainMap : Map<String, number>,
        public startCurrentMonthMap : Map<String, number>,
        public startCurrentDayMap : Map<String, number>,
    ) {
    }
}

export class ExchangeCheckStatusModel {
    constructor(
        public allowed : boolean,
    ) {
    }
}