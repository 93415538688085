import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

import {SERVER_API_URL} from '../constants/app.constants';
import {Observable} from 'rxjs';
import { StrategySymbolSplitDto, StrategySymbolSplitModel } from '../model/strategy-symbols/strategy-symbols.model';

@Injectable()
export class StrategySymbolService {
    private strategySymbolResourceUrl = SERVER_API_URL + '/api/strategy-symbol-splits';
 
    constructor(private http: HttpClient) {}


    getAllSymbols(strategyId: number): Observable<StrategySymbolSplitDto[]> {
        let QueryParams = {
            strategyId: strategyId
        }
        return this.http.get<StrategySymbolSplitDto[]>(this.strategySymbolResourceUrl, {
            params: QueryParams
        });
    }

    editStrategy(strategy: StrategySymbolSplitModel, id: any) {
        return this.http.put(`${this.strategySymbolResourceUrl}/${id}`, strategy);
    }

    deleteStrategy(id: number): Observable<any> {
        return this.http.delete(`${this.strategySymbolResourceUrl}/${id}`);
    }

    createStrategy(strategy: StrategySymbolSplitModel): Observable<StrategySymbolSplitDto> {
        return this.http.post<StrategySymbolSplitDto>(this.strategySymbolResourceUrl , strategy);
    }


}