import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ExchangeAccountService {
  private _newExchangeAccountAdded = new Subject<void>();

  // Observable for the new exchange account added event
  newExchangeAccountAdded$ = this._newExchangeAccountAdded.asObservable();

  // Method to emit the new exchange account added event
  notifyNewExchangeAccountAdded(): void {
    this._newExchangeAccountAdded.next();
  }
}
