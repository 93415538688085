import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'jhi-register',
    templateUrl: './social-register.component.html'
})
export class SocialRegisterComponent implements OnInit, OnDestroy  {
    success: boolean;
    error: boolean;
    errorKey: string;
    provider: string;
    providerLabel: string;
    private routeSub;

    constructor(
        private route: ActivatedRoute,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe((queryParams) => {
            this.success = ('success' === queryParams['success']);
            if (!this.success) {
                this.error = !this.success;
                this.errorKey = 'social.register.messages.error.fail';
                if (queryParams['err'] === 'exists') {
                    this.errorKey = 'social.register.messages.error.user.exists';
                }
            }
        });
        this.routeSub = this.route.params.subscribe((params) => {
            this.provider = params['provider?{success:boolean}'];
        });
        this.providerLabel = this.provider.charAt(0).toUpperCase() + this.provider.slice(1);
    }

    login() {
        this.router.navigate(['/login'], {replaceUrl: true} );
    }

    getErrorKey() {
        return this.errorKey;
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
